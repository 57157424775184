import { ProductCost } from 'api/Product/model/ProductCost';
import { ProductId } from 'api/Product/model/ProductId';
import moment from 'moment-timezone';

export class ProductCostSettingEvent {
    constructor(
        private readonly _productId: ProductId,
        private readonly _productCost: ProductCost,
        private readonly _time: moment.Moment,
    ) {}

    get productId(): ProductId {
        return this._productId;
    }

    get productCost(): ProductCost {
        return this._productCost;
    }

    get time(): moment.Moment {
        return this._time;
    }
}

