import React from 'react';
import './Flex.scss';

export type FlexDirection = 'row' | 'column' | 'row-reverse' | 'column-reverse';
export type FlexAlign = 'start' | 'end' | 'center' | 'baseline' | 'stretch';
export type FlexJustify = 'start' | 'end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
export type FlexWrap = 'wrap' | 'nowrap' | 'wrap-reverse';

export interface IFlexProps {
    readonly className ? : string;
    readonly ariaLabel ? : string;
    readonly direction : FlexDirection;
    readonly grow ? : number;
    readonly shrink ? : number;
    readonly basis ? : number | 'auto';
    readonly align ? : FlexAlign;
    readonly justify ? : FlexJustify;
    readonly inline ? : boolean;
    readonly wrap ? : FlexWrap;
    readonly bg ? : string | null;
    readonly onClick ? : (event : React.MouseEvent<HTMLElement>) => void;
    readonly children? : React.ReactNode;
}

// Generic props and state to allow abstractions of this Class
export class Flex extends React.Component<IFlexProps, object> {
    public render() : JSX.Element {
        const {
            children,
            className,
            ariaLabel,
            direction,
            grow,
            shrink,
            basis,
            align,
            justify,
            inline,
            wrap,
            bg,
            onClick
        } = this.props;

        let classNames : string = `flex-ui`;

        if (className !== undefined) {
            classNames += ` ${className}`;
        }
        if (direction) {
            classNames += ` flex-direction-${direction}`;
        }
        if (inline) {
            classNames += ` flex-inline`;
        }
        if (typeof grow === 'number') {
            classNames += ` flex-grow-${grow}`;
        }
        if (typeof shrink === 'number') {
            classNames += ` flex-shrink-${shrink}`;
        }
        if (typeof basis === 'number') {
            classNames += ` flex-basis-${basis}`;
        }
        if (align) {
            classNames += ` flex-align-${align}`;
        }
        if (justify) {
            classNames += ` flex-justify-${justify}`;
        }
        if (bg) {
            classNames += ` flex-bg-${bg}`;
        }
        if (wrap) {
            classNames += ` flex-wrap-${wrap}`;
        }

        return (
            <div className={ classNames } aria-label={ ariaLabel } onClick={ onClick }>
                { children }
            </div>
        );
    }
}
