import { Unit } from 'api/Product/model/Unit';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export enum BaseUnit {
    EACH = 'EA',
    UNIT = 'unit',
}

const unitByValue = new Map<string, BaseUnit>();
Object.keys(BaseUnit).forEach((key) => {
    // @ts-ignore
    unitByValue.set(BaseUnit[key as any], BaseUnit[key as any] as BaseUnit);
});

export namespace BaseUnit {
    export function isBaseUnit(unit : Unit) : unit is BaseUnit {
        return (typeof unitByValue.get(unit) !== 'undefined');
    }

    export function isBaseUnitValue(unitValue : string) : boolean {
        return (typeof unitByValue.get(unitValue) !== 'undefined');
    }

    export function getByBaseUnitValue(unitValue : string) : BaseUnit {
        const unit = unitByValue.get(unitValue);

        if (typeof unit === 'undefined') {
            throw new RuntimeException('unexpected value: ' + unitValue);
        }

        return unit;
    }

    export function getUnits() : Array<BaseUnit> {
        return Array.from(unitByValue.values());
    }
}
