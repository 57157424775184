import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { ICookieReader } from 'shared/lib/cookie/interfaces/ICookieReader';
import { IActionCreatorsMapObject } from 'shared/models/IAction';

import { IUserInviteService } from 'api/UserAccount/interfaces/IUserInviteService';
import { InviteInfo } from 'api/UserAccount/model/InviteInfo';
import { UserAccountId } from 'api/UserAccount/model/UserAccountId';
import { UserSessionId } from 'api/UserAccount/model/UserSessionId';

import { ISignUpOptionsState } from './index';
import { IUserSessionAppState } from './reducers';

export namespace ActionInterfaces {
    export interface IUserSessionInviteActionCreatorsMapObject extends IActionCreatorsMapObject {
        getInvite : (
            inviteLink : string,
        ) => ThunkAction<Promise<InviteInfo>, object, {services : ActionInterfaces.IServices}>;
        acceptInvite : (
            userAccountIdentifier : UserAccountId,
            inviteLink : string,
        ) => ThunkAction<Promise<string>, object, {services : ActionInterfaces.IServices}>;
        invalidateInvite : (inviteLink : string) => ThunkAction<Promise<string>, object, {services : ActionInterfaces.IServices}>;
        onSuccessfulSignIn : () => ThunkAction<Promise<UserAccountId | null>, object, { services : ActionInterfaces.IServices }> ;
        logoutUser : () => ThunkAction<Promise<void>, object, { services : ActionInterfaces.IServices }>;
    }

    export interface IServices {
        userInviteService : IUserInviteService;
        cookieReader : ICookieReader;
        userSessionTerminator : any;
        userSessionManager : any;
        unauthenticatedUserSessionManager : any;
    }
}

const getInvite = (
    inviteLink : string,
) : ThunkAction<Promise<InviteInfo>, object, {services : ActionInterfaces.IServices}> => {
    return (dispatch : Dispatch<object>, getState : () => object, extraArguments : {services : ActionInterfaces.IServices}) : Promise<InviteInfo> => {
        return extraArguments.services.userInviteService.getInvite(inviteLink);
    };
};

const acceptInvite = (
    userAccountIdentifier : UserAccountId,
    inviteLink : string,
) : ThunkAction<Promise<string>, object, {services : ActionInterfaces.IServices}> => {
    return (dispatch : Dispatch<object>, getState : () => object, extraArguments : {services : ActionInterfaces.IServices}) : Promise<string> => {
        return extraArguments.services.userInviteService.addCustomerToRetailer(userAccountIdentifier, inviteLink);
    };
};

const invalidateInvite = (
    inviteLink : string,
) : ThunkAction<Promise<string>, object, { services : ActionInterfaces.IServices }> => {
    return (dispatch : Dispatch<object>, getState : () => object, extraArguments : { services : ActionInterfaces.IServices }) : Promise<string> => {
        return extraArguments.services.userInviteService.invalidateInvite(inviteLink);
    };
};

const onSuccessfulSignIn = () : ThunkAction<Promise<UserAccountId | null>, object, { services : ActionInterfaces.IServices }> => {
    return (dispatch : Dispatch<object>, getState : () => object, extraArguments : { services : ActionInterfaces.IServices }) : Promise<UserAccountId | null> => {
        const authenticatedUserSessionId : UserSessionId = extraArguments.services.userSessionManager.getSessionId();
        if (authenticatedUserSessionId !== null) {
            return extraArguments.services.userSessionManager.getAccountId();
        }
        return Promise.resolve(null);
    };
};

const logoutUser = () : ThunkAction<Promise<void>, object, { services : ActionInterfaces.IServices }> => {
    return (dispatch : Dispatch<object>, getState : () => object, extraArguments : { services : ActionInterfaces.IServices }) : Promise<void> => {
        return extraArguments.services.userSessionTerminator.terminateSession();
    };
};

export const UserSessionInviteActions : ActionInterfaces.IUserSessionInviteActionCreatorsMapObject = {
    getInvite,
    acceptInvite,
    invalidateInvite,
    onSuccessfulSignIn,
    logoutUser
};
