import MoneyModel from 'gen-thrift/money_Model_types';
import ProductAmountModel from 'gen-thrift/product_amount_Model_types';
import ProductModel from 'gen-thrift/product_Model_types';

import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { BaseUnit } from 'api/Product/model/BaseUnit';
import { MassUnit } from 'api/Product/model/MassUnit';
import { PackagingId } from 'api/Product/model/PackagingId';
import { PackagingUnit } from 'api/Product/model/PackagingUnit';
import { ProductAmount } from 'api/Product/model/ProductAmount';
import { ProductId } from 'api/Product/model/ProductId';
import { ProductQuantityUnit } from 'api/Product/model/ProductQuantityUnit';
import { Unit } from 'api/Product/model/Unit';
import { VolumeUnit } from 'api/Product/model/VolumeUnit';

import { ProductCost } from 'api/Product/model/ProductCost';
import { QuantityInUnit } from 'api/Product/model/QuantityInUnit';
import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { decimalToNumber } from 'shared/utils/decimalUtils';
import { ProductCostSettingEvent } from '../model/ProductCostSettingEvent';

import moment from 'moment-timezone';

export class ProductThriftToObjectSerializer {

    ////////////////////////////////////
    // CORE MODELS
    ////////////////////////////////////
    public getProductId (
        productId : ProductModel.ProductId
    ) : ProductId {
        return new ProductId(productId.value);
    }

    public getThriftPackagingId(
        packagingId : ProductModel.PackagingId,
    ) : PackagingId {
        return new PackagingId(packagingId.value);
    }

    public getProductIds (
        productIds : Array<ProductModel.ProductId>
    ) : Array<ProductId> {
        return productIds.map((productId : ProductModel.ProductId) => {
            return new ProductId(productId.value);
        });
    }

    public getProductIdsAsSet (
        productIds : Array<ProductModel.ProductId>
    ) : StringValueSet<ProductId> {
        const productIdSet = new StringValueSet<ProductId>();
        productIds.forEach((productId : ProductModel.ProductId) => {
            productIdSet.add(new ProductId(productId.value));
        });
        return productIdSet;
    }

    public getProductQuantityUnit (
        productQuantityUnit : ProductModel.ProductQuantityUnit
    ) : ProductQuantityUnit {
        if (productQuantityUnit.packagingId !== null) {
            return new PackagingId(productQuantityUnit.packagingId.value);
        } else if (productQuantityUnit.unitOfMass !== null) {
            return this.getMassUnit(productQuantityUnit.unitOfMass);
        } else if (productQuantityUnit.unitOfVolume !== null) {
            return this.getVolumeUnit(productQuantityUnit.unitOfVolume);
        }

        throw new RuntimeException(`unexpected value for productQuantityUnit: ${ productQuantityUnit }`);
    }

    public getQuantityInUnit(
        quantityOfProduct : ProductModel.QuantityOfProduct,
    ) : QuantityInUnit<ProductQuantityUnit> {
        return new QuantityInUnit(
            decimalToNumber(quantityOfProduct.quantity),
            this.getProductQuantityUnit(quantityOfProduct.productQuantityUnit),
        );
    }

    public getProductAmount(
        productAmount : ProductModel.ProductIdAndQuantityOfProduct,
    ) : ProductAmount {
        return new ProductAmount(
            this.getProductId(productAmount.productId),
            this.getQuantityInUnit(productAmount.quantityOfProduct),
        );
    }

    public getMassUnit (
        unit : ProductAmountModel.MassUnit
    ) : MassUnit {
        switch (unit) {
            case ProductAmountModel.MassUnit.METRIC_KILOGRAM:
                return MassUnit.KILOGRAM;
            case ProductAmountModel.MassUnit.METRIC_GRAM:
                return MassUnit.GRAM;
            case ProductAmountModel.MassUnit.US_POUND:
                return MassUnit.POUND;
            case ProductAmountModel.MassUnit.US_DRY_OUNCE:
                return MassUnit.DRY_OUNCE;
            default:
                throw new RuntimeException(`unexpected value for mass unit: ${ unit }`);
        }
    }

    public getVolumeUnit (
        unit : ProductAmountModel.VolumeUnit
    ) : VolumeUnit {
        switch (unit) {
            case ProductAmountModel.VolumeUnit.METRIC_LITER:
                return VolumeUnit.LITER;
            case ProductAmountModel.VolumeUnit.METRIC_CENTILITER:
                return VolumeUnit.CENTILITER;
            case ProductAmountModel.VolumeUnit.METRIC_MILLILITER:
                return VolumeUnit.MILLILITER;
            case ProductAmountModel.VolumeUnit.US_FLUID_OUNCE:
                return VolumeUnit.OUNCE;
            case ProductAmountModel.VolumeUnit.US_FLUID_GALLON:
                return VolumeUnit.GALLON;
            case ProductAmountModel.VolumeUnit.US_FLUID_QUART:
                return VolumeUnit.QUART;
            case ProductAmountModel.VolumeUnit.US_FLUID_PINT:
                return VolumeUnit.PINT;
            case ProductAmountModel.VolumeUnit.BAR_SPOON:
                return VolumeUnit.BAR_SPOON;
            case ProductAmountModel.VolumeUnit.DASH:
                return VolumeUnit.DASH;
            default:
                throw new RuntimeException(`unexpected value for volume unit: ${ unit }`);
        }
    }

    public getMeasurableUnit(unit : ProductModel.UnitOfMeasure) : VolumeUnit | MassUnit {
        if (unit.unitOfMass !== null) {
            return this.getMassUnit(unit.unitOfMass);
        } else if (unit.unitOfVolume !== null) {
            return this.getVolumeUnit(unit.unitOfVolume);
        } else {
            throw new RuntimeException('unit of measure unexpectedly not set');
        }
    }

    public getUnit (
        unit : ProductAmountModel.Unit
    ) : Unit {
        switch (unit) {
            case ProductAmountModel.Unit.CASE:
                return PackagingUnit.CASE ;
            case ProductAmountModel.Unit.KEG:
                return PackagingUnit.KEG;
            case ProductAmountModel.Unit.OTHER_CONTAINER:
                return PackagingUnit.OTHER;
            case ProductAmountModel.Unit.BAG:
                return PackagingUnit.BAG;
            case ProductAmountModel.Unit.BOX:
                return PackagingUnit.BOX;
            case ProductAmountModel.Unit.CAN_FOOD:
                return PackagingUnit.CAN_FOOD;
            case ProductAmountModel.Unit.CARTON:
                return PackagingUnit.CARTON;
            case ProductAmountModel.Unit.CONTAINER:
                return PackagingUnit.CONTAINER;
            case ProductAmountModel.Unit.PACKAGE:
                return PackagingUnit.PACKAGE;
            case ProductAmountModel.Unit.TUB:
                return PackagingUnit.TUB;
            case ProductAmountModel.Unit.CAN:
                return PackagingUnit.CAN;
            case ProductAmountModel.Unit.BOTTLE:
                return PackagingUnit.BOTTLE;
            case ProductAmountModel.Unit.METRIC_LITER:
                return VolumeUnit.LITER;
            case ProductAmountModel.Unit.METRIC_CENTILITER:
                return VolumeUnit.CENTILITER;
            case ProductAmountModel.Unit.METRIC_MILLILITER:
                return VolumeUnit.MILLILITER;
            case ProductAmountModel.Unit.US_FLUID_OUNCE:
                return VolumeUnit.OUNCE;
            case ProductAmountModel.Unit.US_FLUID_GALLON:
                return VolumeUnit.GALLON;
            case ProductAmountModel.Unit.US_FLUID_QUART:
                return VolumeUnit.QUART;
            case ProductAmountModel.Unit.US_FLUID_PINT:
                return VolumeUnit.PINT;
            case ProductAmountModel.Unit.BAR_SPOON:
                return VolumeUnit.BAR_SPOON;
            case ProductAmountModel.Unit.DASH:
                return VolumeUnit.DASH;
            case ProductAmountModel.Unit.METRIC_KILOGRAM:
                return MassUnit.KILOGRAM;
            case ProductAmountModel.Unit.METRIC_GRAM:
                return MassUnit.GRAM;
            case ProductAmountModel.Unit.US_POUND:
                return MassUnit.POUND;
            case ProductAmountModel.Unit.US_DRY_OUNCE:
                return MassUnit.DRY_OUNCE;
            case ProductAmountModel.Unit.EACH:
                return BaseUnit.EACH;
            case ProductAmountModel.Unit.UNIT:
                return BaseUnit.UNIT;
            default:
                throw new RuntimeException(`unexpected value for unit: ${ unit }`);
        }
    }

    public getDollarCost(
        monetaryValue : MoneyModel.MonetaryValue
    ) : number {
        if (monetaryValue.currency !== MoneyModel.Currency.USD) {
            throw new RuntimeException('getCollarCost encountered non-USD currency');
        }

        return decimalToNumber(monetaryValue.value);
    }

    public getProductCost(
        productCost : ProductModel.ProductCost
    ) : ProductCost {
        return new ProductCost(
            productCost.monetaryValue.value,
            this.getProductQuantityUnit(productCost.productQuantityUnit)
        );
    }

    public getProductCostSettingEvent(
        event: ProductModel.ProductCostSettingEvent
    ) : ProductCostSettingEvent {
        return new ProductCostSettingEvent(
            this.getProductId(event.productId),
            this.getProductCost(event.productCost),
            moment(event.timestamp.timeSinceUnixEpoch.value)
        );
    }
    public getCostByProductId(
        productIdAndCosts : Array<ProductModel.ProductIdAndCost>
    ) : StringValueMap<ProductId, ProductCost> {
        const costByProductId = new StringValueMap<ProductId, ProductCost>();
        productIdAndCosts.forEach((productIdAndCost) => {
            costByProductId.set(
                this.getProductId(productIdAndCost.productId),
                this.getProductCost(productIdAndCost.productCost)
            );
        });
        return costByProductId;
    }

    public getQuantityOfProductByProductId(
        productIdAndQuantityOfProducts : Array<ProductModel.ProductIdAndQuantityOfProduct>
    ) : StringValueMap<ProductId, QuantityInUnit<ProductQuantityUnit>> {
        const quantityOfProductByProductId = new StringValueMap<ProductId, QuantityInUnit<ProductQuantityUnit>>();
        productIdAndQuantityOfProducts.forEach((productIdAndQuantityOfProduct) => {
            quantityOfProductByProductId.set(
                this.getProductId(productIdAndQuantityOfProduct.productId),
                this.getQuantityInUnit(productIdAndQuantityOfProduct.quantityOfProduct)
            );
        });
        return quantityOfProductByProductId;
    }

    ////////////////////////////////////
    // MISC SERIALIZERS
    ////////////////////////////////////
}
