import React from 'react';
import './InlineEditWrapper.scss';

export enum InlineEditComponentSaveState { // TODO could have a "queued" state to keep track of things that haven't started saving?
    SAVE_IN_PROGRESS = 'SAVE_IN_PROGRESS',
    FINISHED_SAVING = 'FINISHED_SAVING'
}

interface IInlineEditWrapperProps<T> {
    className : string;
    inlineEditComponentName : T;

    inlineEditSaveState : InlineEditComponentSaveState | null | undefined;
    inlineEditingComponentIsShown : boolean | undefined;

    handleOnEditClick : (event : React.MouseEvent<HTMLElement> | React.FocusEvent<HTMLElement>, field : T) => void;
    children : React.ReactNode;
}

// TODO if this is ever used in more places than just MyItemRow and RecentUploadItemRow, it should also wrap the inline edit components.
export class InlineEditWrapper<T> extends React.PureComponent<IInlineEditWrapperProps<T>, object> {
    public render() {
        const {
            inlineEditingComponentIsShown,
            inlineEditSaveState,
            children,
            className
        } = this.props;

        // note: ellipsis-out messes with our dropdown components, so cannot use if inline edit component is open...
        let classes = className + ' inline-edit-wrapper';
        if (inlineEditingComponentIsShown) {
            classes += ' inline-edit-shown';
        }

        return (
            <div className={ classes } tabIndex={ 0 } onClick={ this.handleOnClick } onFocusCapture={ this.handleOnClick }>
                { children }
                <div className="save-state-icon-wrapper">
                    { !inlineEditingComponentIsShown && inlineEditSaveState === InlineEditComponentSaveState.SAVE_IN_PROGRESS &&
                        <span className="save-state-icon bevicon bevico-spinner8" />
                    }
                    { !inlineEditingComponentIsShown && inlineEditSaveState === InlineEditComponentSaveState.FINISHED_SAVING &&
                        <span className="save-state-icon bevicon bevico-check" />
                    }
                </div>
            </div>
        );
    }

    private readonly handleOnClick = (event : React.MouseEvent<HTMLElement> | React.FocusEvent<HTMLElement>) => {
        if (!this.props.inlineEditingComponentIsShown) {
            this.props.handleOnEditClick(event, this.props.inlineEditComponentName);
        }
    }
}
