import React from 'react';

import { SalesItem } from 'api/SalesItem/model/SalesItem';
import { SalesItemUtils } from 'api/SalesItem/utils/SalesItemUtils';

import { Flex } from 'shared/components/FlexLayout/Flex';
import { Popover } from 'shared/components/Popover/Popover';
import { BrandAndName } from 'shared/components/Product/BrandAndName';
import { FormatMonetaryValueWithCents } from 'shared/utils/FormatMonetaryValue';

import { IIngredientInformation } from 'api/SalesItem/utils/SalesItemDisplayUtils';
import { SalesItemManagerUtils } from 'apps/SalesItemManager/utils/SalesItemManagerUtils';

import './SalesItemInfoPopover.scss';
import { numberUtils } from 'shared/utils/numberUtils';

export interface IInfoPopoverProps {
    salesItem : SalesItem;
    salesItemInfoIsShown : boolean;
    setSalesItemInfoIsShown : (isShown : boolean) => void;
    onFullDetailsClick : (() => void) | null;
    salesItemIngredients : Array<IIngredientInformation>;
    salesItemTotalCost : number | null;
    children : React.ReactNode;
}

export class SalesItemInfoPopover extends React.Component<IInfoPopoverProps, object> {

    public render() {
        const {
            setSalesItemInfoIsShown,
            salesItemInfoIsShown,
            salesItem,
            children,
            onFullDetailsClick,
            salesItemIngredients,
            salesItemTotalCost,
        } = this.props;

        const menuGroupText = salesItem.getMenuGroup();
        const yieldQuantity = salesItem.getItemYield().getQuantity();
        const yieldUnit = salesItem.getItemYield().getUnit() === null ? salesItem.getSalesItemCustomUnitName() : salesItem.getItemYield().getUnit();
        const totalCost = FormatMonetaryValueWithCents(salesItemTotalCost);
        const salesPrice = FormatMonetaryValueWithCents(salesItem.getSalesPrice());
        const costPercentageCalculation = SalesItemUtils.getCostPercentageOfSalesItem(salesItem.getSalesPrice(), salesItemTotalCost);
        const costPercentage = SalesItemManagerUtils.formatPercent(costPercentageCalculation);
        return (
            <Popover
                className="sales-item-info-popover"
                preferredPositionArray={ ['below', 'above', 'left', 'right'] }
                showOnHover={ true }
                popoverContentIsShown={ salesItemInfoIsShown }
                setPopoverContentIsShown={ setSalesItemInfoIsShown }
                anchorContent={ (
                    <div className="sales-item-info-anchor">
                        { children }
                    </div>
                ) }
                popoverContent={ (
                    <React.Fragment>
                        <Flex direction="row" className="sales-item-info-header" align="end">
                            <Flex direction="column" grow={ 1 }>
                                <BrandAndName brand="" name={ salesItem.getName() }/>
                            </Flex>
                            <Flex direction="column" grow={ 1 } align="end" justify="end">
                                <div className="sales-item-info-label">
                                    Yield
                                </div>
                                <div className="sales-item-info-value ellipsis-out">
                                    { `${yieldQuantity} ${yieldUnit}` }
                                </div>
                            </Flex>
                        </Flex>
                        <div className="sales-item-info-container">
                            <div className="divider-line"/>
                            <div className="sales-item-info-row">
                                <div className="sales-item-info-label">
                                    Menu Group
                                </div>
                                <div className="sales-item-info-value ellipsis-out">
                                    { menuGroupText }
                                </div>
                            </div>
                            <div className="sales-item-info-row ingredients-container">
                                <div className="sales-item-info-label ingredients-label">
                                    Ingredients
                                </div>
                                <ul className="ellipsis-out ingredients">
                                    {
                                        salesItemIngredients.map((ingredient) => {
                                            return (
                                                <li className="ingredient-row" key={ ingredient.ingredientId.getValue() }>
                                                    <div className="">
                                                        <div className="ingredient-quantity-and-unit">
                                                            { numberUtils.FormatToMaximumTwoDecimalPlaces(ingredient.quantityAmount) } { ingredient.quantityUnitLabel }
                                                            <strong className="ingredient-name">{ ingredient.ingredientLabel }</strong>
                                                        </div>
                                                        <div className="sales-item-info-value ingredient-cost">
                                                            { `$${Number(ingredient.costOfIngredient).toFixed(2)}` }
                                                        </div>
                                                    </div>

                                                </li>
                                            );
                                        })
                                    }
                                </ul>
                            </div>
                            <div className="sales-item-sub-info-row">
                                <div className="sales-item-info-label cost-data">
                                    Total Cost
                                </div>
                                <div className="sales-item-info-value ellipsis-out">
                                    { totalCost }
                                </div>
                            </div>
                            <div className="sales-item-sub-info-row">
                                <div className="sales-item-info-label cost-data">
                                    Cost &#37;
                                </div>
                                <div className="sales-item-info-value ellipsis-out">
                                    { costPercentage }
                                </div>
                            </div>
                            <div className="sales-item-sub-info-row">
                                <div className="sales-item-info-label cost-data">
                                    Sales Price
                                </div>
                                <div className="sales-item-info-value ellipsis-out">
                                    { salesPrice }
                                </div>
                            </div>
                            { onFullDetailsClick !== null &&
                                <div className="full-details-link-container">
                                    <div className="divider-line"/>
                                    <a onClick={ onFullDetailsClick }>
                                        See full details
                                    </a>
                                </div>
                            }
                        </div>
                    </React.Fragment>
                ) }
            />
        );
    }
}
