import React from 'react';

import './StickyHeaderWrapper.scss';

interface IStickyHeaderWrapperProps {
    onIsStuckChange? : (newIsStuck : boolean) => void;
    getTopInset? : () => number;
    headerContainerClassName? : string;
    shouldStickLeft? : boolean;
    children : any;
}

interface IStickyHeaderWrapperStyleState {
    top? : number;
    left? : number;
    width? : number;
    position? : 'static' | 'relative' | 'absolute' | 'fixed' | 'sticky';
}

interface IStickyHeaderWrapperState {
    isStuck : boolean;
    style : IStickyHeaderWrapperStyleState;
}

export const PAGE_SIDEBAR_TOGGLE_EVENT_NAME = 'pageSidebarToggle';
export const NAV_BAR_COMPRESS_TOGGLE_EVENT_NAME = 'navBarCompressToggle';

export class StickyHeaderWrapper extends React.Component<IStickyHeaderWrapperProps, IStickyHeaderWrapperState> {

    private framePending : boolean;
    private stickyHeaderWrapper : HTMLDivElement | null;
    private stickyHeaderWrapperRefFunction : (stickyHeaderWrapper : HTMLDivElement) => void;

    public constructor(props : IStickyHeaderWrapperProps) {
        super(props);

        this.framePending = false;
        this.stickyHeaderWrapper = null;
        this.stickyHeaderWrapperRefFunction = this.stickyHeaderWrapperRefFunctionFactory();
        this.state = {
            isStuck: false,
            style: { }
        };

        setTimeout(() => {
            return this.handleContainer();
        }, 1000);
    }

    public componentDidMount() {
        window.addEventListener('scroll', this.handleContainerEvent, true);
        // window.addEventListener('touchstart', this.handleContainerEvent, true);
        // window.addEventListener('touchmove', this.handleContainerEvent, true);
        // window.addEventListener('touchend', this.handleContainerEvent, true);
        window.addEventListener('resize', this.handlePageResize);
        window.addEventListener('pageshow', this.handleContainerEvent);

        document.addEventListener(NAV_BAR_COMPRESS_TOGGLE_EVENT_NAME, this.handleNavBarResize);
        document.addEventListener(PAGE_SIDEBAR_TOGGLE_EVENT_NAME, this.handlePageSidebarResize);
    }

    public componentWillUnmount() {
        window.removeEventListener('scroll', this.handleContainerEvent, true);
        // window.removeEventListener('touchstart', this.handleContainerEvent, true);
        // window.removeEventListener('touchmove', this.handleContainerEvent, true);
        // window.removeEventListener('touchend', this.handleContainerEvent, true);
        window.removeEventListener('resize', this.handlePageResize);
        window.removeEventListener('pageshow', this.handleContainerEvent);

        document.removeEventListener(NAV_BAR_COMPRESS_TOGGLE_EVENT_NAME, this.handleNavBarResize);
        document.removeEventListener(PAGE_SIDEBAR_TOGGLE_EVENT_NAME, this.handlePageSidebarResize);
    }

    public shouldComponentUpdate(nextProps : IStickyHeaderWrapperProps, nextState : IStickyHeaderWrapperState) {
        return (
            (this.state.isStuck !== nextState.isStuck) ||
            (this.state.style.top !== nextState.style.top) ||
            (this.state.style.left !== nextState.style.left) ||
            (this.state.style.width !== nextState.style.width) ||
            (this.state.style.position !== nextState.style.position) ||
            (this.props.getTopInset !== nextProps.getTopInset) ||
            (this.props.headerContainerClassName !== nextProps.headerContainerClassName) ||
            (this.props.children !== nextProps.children)
        );
    }

    public render() {
        let stickyHeaderWrapperHeight : number | undefined;
        if (this.stickyHeaderWrapper != null) {
            stickyHeaderWrapperHeight = (this.stickyHeaderWrapper.lastElementChild as HTMLElement).offsetHeight;
        }

        return (
            <div
                className={ 'sticky-header-wrapper' + (this.state.isStuck ? ' stuck' : '') }
                ref={ this.stickyHeaderWrapperRefFunction }
                style={ this.state.isStuck ? { height: stickyHeaderWrapperHeight } : {} }
            >
                <div/>
                <div
                    className={ `header-wrapper-container ` + (this.props.headerContainerClassName ? this.props.headerContainerClassName : '') }
                    style={ this.state.style }
                >
                    { this.props.children }
                </div>
            </div>
        );
    }

    private handleNavBarResize = (event : Event) => {
        this.handleResizeEvent(event, 280);
    }

    private handlePageResize = (event : Event) => {
        this.handleResizeEvent(event, 200);
    }

    private handlePageSidebarResize = (event : Event) => {
        this.handleResizeEvent(event, 0);
    }

    private handleResizeEvent = (event : Event, timeout : number) => { // timeout to wait for any applicable animations to finish
        setTimeout(() => {
            return this.handleContainerEvent(event);
        }, timeout);
    }

    private handleContainerEvent = (event : Event) => {
        if (!this.stickyHeaderWrapper) {
            return;
        }

        if ((event.target === document) || (event.target === window) || ((event.target as Node).contains && (event.target as Node).contains(this.stickyHeaderWrapper))) {
            this.handleContainer();
        }
    }

    private handleContainer = () => {
        if (!this.stickyHeaderWrapper) {
            return;
        }

        const parentElement = this.stickyHeaderWrapper.parentElement;
        if (parentElement) {
            if (!this.framePending) {
                window.requestAnimationFrame(() => {
                    if (!this.stickyHeaderWrapper) {
                        return;
                    }

                    this.framePending = false;

                    let heightOffset = 0;

                    const getTopInset = this.props.getTopInset;
                    if (typeof getTopInset === 'undefined') {
                        const contentHeaderElements = document.getElementsByClassName('page-header');
                        if (contentHeaderElements.length) {
                            heightOffset = contentHeaderElements[0].clientHeight;
                        }
                    } else {
                        heightOffset = getTopInset();
                    }

                    const stuckElements = document.querySelectorAll('.sticky-header-wrapper.stuck');
                    for (let i = 0; i < stuckElements.length; i++) {
                        const stuckElement = stuckElements[i];
                        if (stuckElement === this.stickyHeaderWrapper) {
                            break;
                        }

                        if ((stuckElement.parentNode && stuckElement.parentNode.contains(this.stickyHeaderWrapper))) {
                            heightOffset += stuckElement.clientHeight;
                        }
                    }

                    const parentBoundingClientRect = parentElement.getBoundingClientRect();
                    const childBoundingClientRect = (this.stickyHeaderWrapper.firstElementChild as HTMLElement).getBoundingClientRect();

                    const boundingRectTopDifference = childBoundingClientRect.top - parentBoundingClientRect.top;

                    const isStuck = ((parentBoundingClientRect.top <= (heightOffset - boundingRectTopDifference)) || (boundingRectTopDifference < 0)) && (parentBoundingClientRect.bottom > heightOffset);

                    let style : IStickyHeaderWrapperStyleState = {};
                    if (isStuck) {
                        const diff = (parentBoundingClientRect.bottom - heightOffset) - this.stickyHeaderWrapper.clientHeight;
                        style = {
                            top: ((boundingRectTopDifference < 0) && (parentBoundingClientRect.top > heightOffset)) ? parentBoundingClientRect.top : (diff < 0 ? (heightOffset + diff) : heightOffset),
                            width: childBoundingClientRect.width,
                            position: 'fixed',
                        };

                        if (this.props.shouldStickLeft) {
                            style.left = childBoundingClientRect.left;
                        }
                    }

                    this.setState((state : IStickyHeaderWrapperState) => {
                        if (state.isStuck !== isStuck && this.props.onIsStuckChange) {
                            this.props.onIsStuckChange(isStuck);
                        }

                        return {
                            isStuck,
                            style,
                        };
                    });
                });
                this.framePending = true;
            }
        }
    }

    private stickyHeaderWrapperRefFunctionFactory() {
        return (stickyHeaderWrapper : HTMLDivElement) => {
            this.stickyHeaderWrapper = stickyHeaderWrapper;
        };
    }
}
