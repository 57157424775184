import React from 'react';

import { PreventPageScrolling } from 'shared/components/PreventPageScrolling';

import 'shared/css/Modal.scss';

export interface IModalProps {
    className : string;
    children : React.ReactNode;
}

/**
 * DEPRECATED. Use ModalFlex or Dialog
 */
export class Modal extends React.Component<IModalProps, object> {

    public render() {
        return (
            <div className={ this.props.className }>
                <PreventPageScrolling
                    preventWindowTouchMove={ false }
                    addModalOpenClass={ true }
                />
                <div className="bevspot-modal">
                    <div className="content modal-content">
                        { this.props.children }
                    </div>
                </div>
            </div>
        );
    }
}
