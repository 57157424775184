import UnauthenticatedUserSessionModel from 'gen-thrift/unauthenticated_user_session_Model_types';

import { UserAccountId } from 'api/UserAccount/model/UserAccountId';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';
import { AjaxUtils } from 'shared/utils/ajaxUtils';
import { PathUtils } from 'shared/utils/pathUtils';
import { URLStringUtils } from 'shared/utils/urlStringUtils';

interface IHubSpotUser {
    email : string;
    phone : string;
    bar_name : string;
    first_name : string;
    last_name : string;
    zip_code : string;
    subscribe_to_blog : boolean;
    hubspot_cookie : string | null;
    is_admin_created : boolean;
    source_url : string;
    should_receive_marketing : boolean;
    creator_user_id : string | null;
    retailer_id : string | null;
}

interface IHubSpotBarProfitUser extends IHubSpotUser {
    bar_connection : string;
}

interface IHubSpotRetailer {
    user_first_name : string;
    user_last_name : string;
    user_email : string;
    user_phone : string;
    beverage_retailer_id : string | null;
    food_retailer_id : string | null;
    retailer_name : string;
    retailer_address_line1 : string;
    retailer_address_line2 : string;
    retailer_country_code : string;
    retailer_city : string;
    retailer_zipcode : string;
    group_location_count : string | null;
    introduction_source : string | null;
    introduction_drilldown : string | null;
    introduction_free_text : string | null;
    product_subscription : string | null;
    source_url : string;
    is_admin_created : boolean;
    should_receive_marketing : boolean;
    creator_user_id : string;
    hubspot_cookie : string | null;
    promo_code : string | null;
    getting_started_source? : string | null;
    beverage_free_trial_getting_started_type? : string | null;
    food_free_trial_getting_started_type? : string | null;
}

export const Observer = (() => {

    const trackGoogleTagManager = (event : string, location? : string) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event,
            location
        });
        return new Promise((resolve) => {
            setTimeout(resolve, 500); // give the trackers the opportunity to complete
        });
    };

    const trackGoogleAdwordsCreateUser = () => {
        const conversionHandler = window.google_trackConversion;
        if (typeof conversionHandler === 'function') {
            window.google_conversion_id = 950936996;
            window.google_conversion_label = 'tUuHCMbXlF8QpMu4xQM';
            window.google_remarketing_only = false;
            window.google_conversion_format = '3';
            conversionHandler({});
        }
    };

    const sendRetailerCreationToHubspot = (retailer : IHubSpotRetailer) : Promise<void> => {
        if ((retailer.beverage_retailer_id === null) && (retailer.food_retailer_id === null)) {
            throw new RuntimeException('beverageRetailerName and foodRetailerName cannot both be null');
        }

        const formData : FormData = new FormData();
        formData.append('user_first_name', retailer.user_first_name);
        formData.append('user_last_name', retailer.user_last_name);
        formData.append('user_email', retailer.user_email);
        formData.append('user_phone', retailer.user_phone);

        if (retailer.beverage_retailer_id) {
            formData.append('beverage_retailer_id', retailer.beverage_retailer_id);
        }

        if (retailer.food_retailer_id) {
            formData.append('food_retailer_id', retailer.food_retailer_id);
        }

        formData.append('retailer_name', retailer.retailer_name);
        formData.append('retailer_address_line1', retailer.retailer_address_line1);
        formData.append('retailer_address_line2', retailer.retailer_address_line2);
        formData.append('retailer_country_code', retailer.retailer_country_code);
        formData.append('retailer_city', retailer.retailer_city);
        formData.append('retailer_zipcode', retailer.retailer_zipcode);

        if (retailer.group_location_count) {
            formData.append('group_location_count', retailer.group_location_count);
        } else {
            formData.append('group_location_count', '1');
        }

        if (retailer.introduction_source) {
            formData.append('introduction_source', retailer.introduction_source);
        }

        if (retailer.introduction_drilldown) {
            formData.append('introduction_drilldown', retailer.introduction_drilldown);
        }

        if (retailer.introduction_free_text) {
            formData.append('introduction_free_text', retailer.introduction_free_text);
        }

        if (retailer.product_subscription) {
            formData.append('product_subscription', retailer.product_subscription);
        }

        formData.append('source_url', retailer.source_url);
        formData.append('is_admin_created', '' + retailer.is_admin_created);
        formData.append('should_receive_marketing', '' + retailer.should_receive_marketing);
        formData.append('creator_user_id', retailer.creator_user_id);

        if (retailer.hubspot_cookie) {
            formData.append('hubspot_cookie', retailer.hubspot_cookie);
        }

        if (retailer.promo_code) {
            formData.append('promo_code', retailer.promo_code);
        }

        if (retailer.getting_started_source) {
            formData.append('getting_started_source', retailer.getting_started_source);
        }
        if (retailer.beverage_free_trial_getting_started_type) {
            formData.append('beverage_free_trial_getting_started_type', retailer.beverage_free_trial_getting_started_type);
        }
        if (retailer.food_free_trial_getting_started_type) {
            formData.append('food_free_trial_getting_started_type', retailer.food_free_trial_getting_started_type);
        }

        return AjaxUtils.ajaxPostForm(PathUtils.getAbsolutePathForRequest('/webhooks/hubspot/retailer_created_by_user/'), formData)
        .then(() => {
            /* tslint:disable-next-line */
            console.log('Hubspot retailer_created_by_user SUCCESSS', retailer);
        })
        .catch(() => {
            /* tslint:disable-next-line */
            console.error('Hubspot retailer_created_by_user ERROR', retailer);
        });
    };

    const sendUserSignUpToHubspot = (user : IHubSpotUser) : Promise<void> => {
        const formData : FormData = new FormData();
        formData.append('email', user.email);
        formData.append('phone', user.phone);
        formData.append('bar_name', user.bar_name);
        formData.append('first_name', user.first_name);
        formData.append('last_name', user.last_name);
        formData.append('zip_code', user.zip_code);
        formData.append('subscribe_to_blog', '' + user.subscribe_to_blog);

        if (user.hubspot_cookie) {
            formData.append('hubspot_cookie', user.hubspot_cookie);
        }

        formData.append('is_admin_created', '' + user.is_admin_created);
        formData.append('source_url', user.source_url);
        formData.append('should_receive_marketing', '' + user.should_receive_marketing);

        if (user.creator_user_id) {
            formData.append('creator_user_id', user.creator_user_id);
        }

        if (user.retailer_id) {
            formData.append('retailer_id', user.retailer_id);
        }

        return AjaxUtils.ajaxPostForm(PathUtils.getAbsolutePathForRequest('/webhooks/hubspot/user_sign_up/'), formData)
        .then(() => {
            /* tslint:disable-next-line */
            console.log('Hubspot user_sign_up SUCCESSS', user);
        })
        .catch(() => {
            /* tslint:disable-next-line */
            console.error('Hubspot user_sign_up ERROR', user);
        });
    };

    const sendPartnerSignUpRetailerToHubspot = (retailer : IHubSpotRetailer) : Promise<void> => {
        if ((retailer.beverage_retailer_id === null) && (retailer.food_retailer_id === null)) {
            throw new RuntimeException('beverageRetailerName and foodRetailerName cannot both be null');
        }

        const formData : FormData = new FormData();
        formData.append('user_first_name', retailer.user_first_name);
        formData.append('user_last_name', retailer.user_last_name);
        formData.append('user_email', retailer.user_email);
        formData.append('user_phone', retailer.user_phone);

        if (retailer.beverage_retailer_id) {
            formData.append('beverage_retailer_id', retailer.beverage_retailer_id);
        }

        if (retailer.food_retailer_id) {
            formData.append('food_retailer_id', retailer.food_retailer_id);
        }

        formData.append('retailer_name', retailer.retailer_name);
        formData.append('retailer_address_line1', retailer.retailer_address_line1);
        formData.append('retailer_address_line2', retailer.retailer_address_line2);
        formData.append('retailer_country_code', retailer.retailer_country_code);
        formData.append('retailer_city', retailer.retailer_city);
        formData.append('retailer_zipcode', retailer.retailer_zipcode);

        if (retailer.group_location_count) {
            formData.append('group_location_count', retailer.group_location_count);
        }

        if (retailer.introduction_source) {
            formData.append('introduction_source', retailer.introduction_source);
        }

        if (retailer.product_subscription) {
            formData.append('product_subscription', retailer.product_subscription);
        }

        formData.append('source_url', retailer.source_url);
        formData.append('is_admin_created', '' + retailer.is_admin_created);
        formData.append('should_receive_marketing', '' + retailer.should_receive_marketing);
        formData.append('creator_user_id', retailer.creator_user_id);

        if (retailer.hubspot_cookie) {
            formData.append('hubspot_cookie', retailer.hubspot_cookie);
        }

        if (retailer.promo_code) {
            formData.append('promo_code', retailer.promo_code);
        }

        return AjaxUtils.ajaxPostForm(PathUtils.getAbsolutePathForRequest('/webhooks/hubspot/partner_sign_up/'), formData)
        .then(() => {
            /* tslint:disable-next-line */
            console.log('Hubspot retailer_created_by_user SUCCESSS', retailer);
        })
        .catch(() => {
            /* tslint:disable-next-line */
            console.error('Hubspot retailer_created_by_user ERROR', retailer);
        });
    };

    return {
        identifyUnauthenticated: (unauthenticatedUserSessionId : UnauthenticatedUserSessionModel.UnauthenticatedUserSessionId) => {
            if ((window.location.host === 'app.bevspot.com') || (window.location.host === 'usertest.bevspot.net')
            || (window.location.host === 'www.bevspot.com') || (window.location.host === 'www.bevspot.net')) {
                if (typeof window.FS !== 'undefined') {
                    window.FS.identify(unauthenticatedUserSessionId.value);
                }
            }

            if (typeof window.analytics !== 'undefined') {
                window.analytics.reset();
                window.analytics.identify(unauthenticatedUserSessionId.value, {
                    isAuthenticatedUser: false,
                    isAssociatedWithRetailer: false,
                });
            }
        },

        identifyAuthenticated: (userAccountId : UserAccountId, userName : string | null, userEmail : string | null, isAssociatedWithRetailer : boolean | null) => {
            if ((window.location.host === 'app.bevspot.com') || (window.location.host === 'usertest.bevspot.net')
            || (window.location.host === 'www.bevspot.com') || (window.location.host === 'www.bevspot.net')) {

                let name = userName;
                if (name === null) {
                    name = window.GLOBAL_USER_NAME || null;
                }

                let email = userEmail;
                if (email === null) {
                    email = window.GLOBAL_USER_EMAIL || null;
                }

                if (typeof window.FS !== 'undefined') {
                    window.FS.identify(userAccountId.getValue(), {
                        name,
                        email,
                    });
                }
            }

            if (typeof window.analytics !== 'undefined') {
                const userProperties = {
                    isAuthenticatedUser: true,
                };

                if (userName) {
                    (userProperties as any).name = userName;
                }

                if (userEmail) {
                    (userProperties as any).email = userEmail;
                }

                if (isAssociatedWithRetailer !== null) {
                    (userProperties as any).isAssociatedWithRetailer = isAssociatedWithRetailer;
                }

                window.analytics.reset();
                window.analytics.identify(userAccountId.getValue(), userProperties);
            }
        },

        observeLogout: () => {
            if (typeof window.analytics !== 'undefined') {
                window.analytics.reset();
            }
        },

        observePageView: () => {
            if (typeof window.analytics !== 'undefined') {
                window.analytics.page(); // may want to add retailer id if possible...
            }
        },

        observeAction: (actionName : string, actionArgs : any) => {
            return new Promise((resolve) => {
                if (typeof window.analytics !== 'undefined') {
                    actionArgs = actionArgs || {};
                    // prevent mutating the passed in argument
                    const analyticsActionArgs = { ...actionArgs } || {};
                    const analyticsContextArgs : any = {};

                    // this is for capturing retailer_id on legacy pages
                    if (window.GLOBAL_RETAILER_ID) {
                        analyticsActionArgs.retailer_id = window.GLOBAL_RETAILER_ID;
                        analyticsContextArgs.retailer_id = window.GLOBAL_RETAILER_ID;
                    }

                    // get the path with /r/<retailer_id> /g/<groupid> and trailing / stripped out
                    const appPath = window.location.pathname.replace(/\/[rg]\/[a-zA-Z0-9_]+(?=\/)|\/$/g, '');
                    analyticsContextArgs.app_path = appPath;
                    analyticsContextArgs.hostname = window.location.hostname;

                    window.analytics.track(actionName, analyticsActionArgs, { context : analyticsContextArgs });
                }

                setTimeout(resolve, 2000); // give the trackers the opportunity to complete
            });
        },

        // TODO: can't use AjaxUtils.ajaxPostForm for HubSpot submissions due to `null` handling in FormData
        trackConversion: {
            sendRetailerCreationToHubspot,
            sendUserSignUpToHubspot,
            sendPartnerSignUpRetailerToHubspot,
            retailerCreated: (retailer : IHubSpotRetailer) => {
                trackGoogleTagManager('CreateRetailer', retailer.beverage_retailer_id || retailer.food_retailer_id || 'UNKNOWN');

                return sendRetailerCreationToHubspot(retailer);
            },
            userCreated: (user : IHubSpotUser) => {
                trackGoogleAdwordsCreateUser();
                trackGoogleTagManager('UserSignup');
                return sendUserSignUpToHubspot(user);
            },
            partnerSignUp: (signup : IHubSpotRetailer) => {
                return sendPartnerSignUpRetailerToHubspot(signup);
            },
            barProfitSubmission: (user : IHubSpotBarProfitUser) => {
                trackGoogleTagManager('BarProfitTool');

                window.$.post(PathUtils.getAbsolutePathForRequest('/webhooks/hubspot/bar_profit_tool/'), user);
            },
            drinkPriceToolSubmission: (user : IHubSpotUser) => {
                trackGoogleTagManager('DrinkPriceTool');

                window.$.post(PathUtils.getAbsolutePathForRequest('/webhooks/hubspot/drink_price_tool/'), user);
            },
            downloadedContent: (href : string, downloadUrl : string | null) => {
                Observer.observeAction('Downloaded Content', {
                    referrer: href,
                    downloadUrl,
                });

                if (downloadUrl !== null) {
                    const downloadLocation = URLStringUtils.getLocation(downloadUrl);
                    if ((typeof window._hsq !== 'undefined') && (downloadLocation !== null)) {
                        window._hsq.push(['setPath', downloadLocation.pathname]);
                        window._hsq.push(['trackPageView']);
                        window._hsq.push(['setPath', window.location.pathname]);
                    }
                }
            },
            inventoryStarted: () => {
                return trackGoogleTagManager('InventoryStart');
            },
            inventoryFinalized: () => {
                return trackGoogleTagManager('InventoryComplete');
            },
            inventoryStorageAreaCreated: (location? : string) => {
                return trackGoogleTagManager('StorageAreaCreateNew', location);
            },
            orderItemCreated: () => {
                return trackGoogleTagManager('OrderCreateItem');
            },
            orderRecorded: () => {
                return trackGoogleTagManager('OrderComplete');
            },
            paymentSubmission: () => {
                return trackGoogleTagManager('PaymentApp');
            },
        },
    };
})();
