import React from 'react';

import { GridLineType } from 'shared/models/Charts/GridLineType';
import { IBoundingBox } from 'shared/models/Charts/IBoundingBox';
import { Orientation } from 'shared/models/Charts/Orientation';
import { HexColor } from 'shared/models/HexColor';

import { utils } from 'shared/components/Charts/utils';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

import { gridLineColor, gridLineOpacity } from 'shared/components/Charts/constants';

export interface IGridLineProps {
    readonly orientation : Orientation;
    readonly fillColor : HexColor | null;
    readonly lineType : GridLineType | null;
    readonly boundingBox : IBoundingBox;
}

export class GridLine extends React.Component<IGridLineProps, object> {
    private classNamePrefix : string = '';

    public render() {
        const {
            boundingBox,
        } = this.props;

        utils.ensureBoundingBoxIsValid(boundingBox);
        this.determineClassNamePrefix();

        const className : string = this.classNamePrefix + '-container';

        return (
            <g
                className={ className }
            >
                { this.createFill() }
                { this.createLine() }
            </g>
        );
    }

    private determineClassNamePrefix = () : void => {
        const {
            orientation,
        } = this.props;

        const className = '-grid-line';
        switch (orientation) {
            case Orientation.HORIZONTAL: {
                this.classNamePrefix = 'horizontal' + className;
                break;
            }
            case Orientation.VERTICAL: {
                this.classNamePrefix = 'vertical' + className;
                break;
            }
            default: {
                throw new RuntimeException('orientation is unexpected value');
            }
        }
    }

    private createFill = () : JSX.Element | null => {
        const {
            fillColor,
            boundingBox,
        } = this.props;

        if (fillColor === null) {
            return null;
        }

        const className : string = this.classNamePrefix + '-fill';

        return (
            <rect
                className={ className }
                height={ boundingBox.heightInPixels }
                width={ boundingBox.widthInPixels }
                fill={ fillColor.getValue() }
            />
        );
    }

    private createLine = () : JSX.Element | null => {
        const {
            orientation,
            lineType,
            boundingBox,
        } = this.props;

        if (lineType === null) {
            return null;
        }

        const className : string = this.classNamePrefix + '-line';

        let x1 : number;
        let x2 : number;
        let y1 : number;
        let y2 : number;
        switch (orientation) {
            case Orientation.HORIZONTAL: {
                x1 = 0;
                x2 = boundingBox.widthInPixels;
                y1 = y2 = boundingBox.heightInPixels / 2;

                break;
            }
            case Orientation.VERTICAL: {
                x1 = x2 = boundingBox.widthInPixels / 2;
                y1 = 0;
                y2 = boundingBox.heightInPixels;

                break;
            }
            default: {
                throw new RuntimeException('orientation is unexpected value');
            }
        }

        let strokeWidthInPixels : number;
        switch (lineType) {
            case GridLineType.THIN_LINE: {
                strokeWidthInPixels = 1;
                break;
            }
            case GridLineType.THICK_LINE: {
                strokeWidthInPixels = 3;
                break;
            }
            default: {
                throw new RuntimeException('unexpected lineType value');
            }
        }

        return (
            <line
                className={ className }

                x1={ x1 }
                y1={ y1 }
                x2={ x2 }
                y2={ y2 }

                stroke={ gridLineColor.getValue() }
                strokeWidth={ strokeWidthInPixels }
                opacity={ gridLineOpacity }
            />
        );
    }
}
