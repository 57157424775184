import React from 'react';

import { AxisPosition } from 'shared/models/Charts/Axes/AxisPosition';
import { IBoundingBox } from 'shared/models/Charts/IBoundingBox';
import { TextPosition } from 'shared/models/Charts/TextPosition';

import { utils } from 'shared/components/Charts/utils';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

import {
    axisTickLabelDistanceFromLineInPixels,
    axisTickLabelHeightInPixels,
    axisTickLineColor,
    axisTickLineLengthInPixels,
} from 'shared/components/Charts/Axes/constants';

export interface IAxisTickProps {
    readonly axisPosition : AxisPosition;
    readonly showTickLine : boolean;
    readonly labelValue : string;
    readonly boundingBox : IBoundingBox;
}

export class AxisTick extends React.Component<IAxisTickProps, object> {
    private classNamePrefix : string = '';

    public render() {
        const {
            boundingBox,
        } = this.props;

        utils.ensureBoundingBoxIsValid(boundingBox);
        this.determineClassNamePrefix();

        const className : string = this.classNamePrefix + '-tick';

        return (
            <g
                className={ className }
            >
                { this.createTickLine() }
                { this.createLabel() }
            </g>
        );
    }

    private determineClassNamePrefix = () : void => {
        const {
            axisPosition,
        } = this.props;

        const className = '-axis';
        switch (axisPosition) {
            case AxisPosition.TOP:
            case AxisPosition.BOTTOM: {
                this.classNamePrefix = 'x' + className;
                break;
            }
            case AxisPosition.LEFT:
            case AxisPosition.RIGHT: {
                this.classNamePrefix = 'y' + className;
                break;
            }
            default: {
                throw new RuntimeException('axisPosition is unexpected value');
            }
        }
    }

    private createTickLine = () : JSX.Element | null => {
        const {
            axisPosition,
            showTickLine,
            boundingBox,
        } = this.props;

        if (!showTickLine) {
            return null;
        }

        const className : string = this.classNamePrefix + '-tick-line';

        let x1 : number;
        let y1 : number;
        let x2 : number;
        let y2 : number;
        switch (axisPosition) {
            case AxisPosition.TOP: {
                x1 = x2 = boundingBox.widthInPixels / 2;

                y1 = boundingBox.heightInPixels;
                y2 = boundingBox.heightInPixels - axisTickLineLengthInPixels;

                break;
            }
            case AxisPosition.BOTTOM: {
                x1 = x2 = boundingBox.widthInPixels / 2;

                y1 = 0;
                y2 = axisTickLineLengthInPixels;

                break;
            }
            case AxisPosition.LEFT: {
                x1 = boundingBox.widthInPixels;
                x2 = boundingBox.widthInPixels - axisTickLineLengthInPixels;

                y1 = y2 = boundingBox.heightInPixels / 2;

                break;
            }
            case AxisPosition.RIGHT: {
                x1 = 0;
                x2 = axisTickLineLengthInPixels;

                y1 = y2 = boundingBox.heightInPixels / 2;

                break;
            }
            default: {
                throw new RuntimeException('axisPosition is unexpected value');
            }
        }

        return (
            <line
                className={ className }
                stroke={ axisTickLineColor.getValue() }

                x1={ x1 }
                y1={ y1 }
                x2={ x2 }
                y2={ y2 }
            />
        );
    }

    private createLabel = () : JSX.Element | null => {
        const {
            axisPosition,
            labelValue,
            boundingBox,
        } = this.props;

        if (labelValue.length === 0) {
            return null;
        }

        const className : string = this.classNamePrefix + '-tick-label';
        const totalLabelPaddingInPixels : number = axisTickLineLengthInPixels + axisTickLabelDistanceFromLineInPixels;

        let x : number;
        let y : number;
        let textPosition : TextPosition;
        switch (axisPosition) {
            case AxisPosition.TOP: {
                x = boundingBox.widthInPixels / 2;
                y = boundingBox.heightInPixels - totalLabelPaddingInPixels;
                textPosition = 'middle';

                break;
            }
            case AxisPosition.BOTTOM: {
                x = boundingBox.widthInPixels / 2;
                y = totalLabelPaddingInPixels + axisTickLabelHeightInPixels;
                textPosition = 'middle';

                break;
            }
            case AxisPosition.LEFT: {
                x = boundingBox.widthInPixels - totalLabelPaddingInPixels;
                y = (boundingBox.heightInPixels + axisTickLabelHeightInPixels) / 2;
                textPosition = 'end';

                break;

            }
            case AxisPosition.RIGHT: {
                x = totalLabelPaddingInPixels;
                y = (boundingBox.heightInPixels + axisTickLabelHeightInPixels) / 2;
                textPosition = 'start';

                break;
            }
            default: {
                throw new RuntimeException('axisPosition is unexpected value');
            }
        }
        return (
            <text
                className={ className }
                x={ x }
                y={ y }
                textAnchor={ textPosition }
            >
                { labelValue }
            </text>
        );
    }
}
