import React from 'react';
import MediaQuery from 'react-responsive';

import { CheckBox } from 'shared/components/CheckBox';
import { MAX_TABLET_WIDTH, MIN_DESKTOP_WIDTH } from 'shared/constants';
import { CheckBoxTriState } from 'shared/models/CheckBoxTriState';

import './RowGroupHeader.scss';

export interface IRowGroupHeaderProps {
    selectAllState : CheckBoxTriState | null;
    onSelectAllClick : () => void;
    groupName : string;
    isOpen : boolean;
    visibleItemCount : number;
    totalItemCount : number;
    onClick : () => void;
    showExpandArrow : boolean;
    children? : React.ReactNode;
}

export class RowGroupHeader extends React.Component<IRowGroupHeaderProps, object> {

    public render() {
        const {
            selectAllState,
            onSelectAllClick,
            groupName,
            isOpen,
            visibleItemCount,
            totalItemCount,
            onClick,
            showExpandArrow,
        } = this.props;

        const itemCountStringVerbose = ((visibleItemCount === totalItemCount) ? '' : `${ visibleItemCount } match${ (visibleItemCount === 1) ? '' : 'es'} of `) + `${ totalItemCount } item${ (totalItemCount === 1) ? '' : 's'}`;
        const itemCountStringMinimal = '(' + ((visibleItemCount === totalItemCount) ? '' : `${ visibleItemCount } of `) + `${ totalItemCount })`;

        return (
            <div className="light-table-group-header group-header-toggle">
                <div className="col-row">
                    { (selectAllState !== null) &&
                        <div className="cell row-start checkbox-container">
                            <CheckBox
                                checkBoxTriState={ selectAllState }
                                isDisabled={ false }
                                onClick={ onSelectAllClick }
                                label={ null }
                            />
                        </div>
                    }
                    <div className="row-content cursor-pointer"  onClick={ onClick }>
                        <div className="cell col-xs-5 col-lg-4">
                            <span className="group-header-title ellipsis-out">{ groupName }</span>
                            <span className="group-header-item-count">
                                <MediaQuery minWidth={ MIN_DESKTOP_WIDTH }>
                                    { itemCountStringVerbose }
                                </MediaQuery>
                                <MediaQuery maxWidth={ MAX_TABLET_WIDTH }>
                                    { itemCountStringMinimal }
                                </MediaQuery>
                            </span>
                        </div>
                        <div className="cell cell-plr-sm col-xs-7 col-lg-8 text-right">
                            <span className="group-header-children">{ this.props.children }</span>
                            { showExpandArrow &&
                                <div className="flex-text-with-icon middle expand-collapse-group">
                                    <strong>{ isOpen ? 'Collapse' : 'Expand' }</strong>
                                    <div className={ `bevicon ${ isOpen ? 'bevico-unfold-less' : 'bevico-unfold-more' }` } />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
