import * as _ from 'lodash';
import React from 'react';

import { IModalButton, IModalClasses } from '../models/Modal';

import { Button } from './Button';
import { Modal } from './Modal';

export interface IModalWithHeaderAndFooterProps {
    className : string;
    shouldComponentUpdate? : boolean;
    buttons : Array<IModalButton> | null;
    closeButtonShown : boolean;
    footerLabel : JSX.Element | null;
    footerLeftButton : IModalButton | null;
    headerText : string | null;
    modalClasses : IModalClasses;
    onClose : (() => void) | null;
    children : React.ReactNode;
}

/**
 * DEPRECATED. Use ModalFlex or Dialog
 */
export class ModalWithHeaderAndFooter extends React.Component<IModalWithHeaderAndFooterProps, object> {

    public shouldComponentUpdate(nextProps : IModalWithHeaderAndFooterProps) {
        return typeof nextProps.shouldComponentUpdate === 'undefined' ? true : !!nextProps.shouldComponentUpdate;
    }

    public render() {
        const {
            className,
            closeButtonShown,
            buttons,
            footerLeftButton,
            footerLabel,
            headerText,
            modalClasses,
            onClose,
        } = this.props;

        if (closeButtonShown && !onClose) {
            throw new Error('Modal showing close button but missing onClose handler');
        }

        const actionElements = buttons !== null ? _.map(buttons, (button, index) => (
            <Button
                key={ index }

                buttonClassName={ button.classes }
                isDisabled={ button.isDisabled }
                isLoading={ button.isLoading }
                onClick={ button.onClick }
            >
                { button.children }
            </Button>
        )) : null;

        const headerClasses = modalClasses.headerClasses;
        const bodyClasses = modalClasses.bodyClasses;
        const footerClasses = modalClasses.footerClasses;

        const handleCloseClick : React.EventHandler<React.MouseEvent<HTMLDivElement>> | undefined = onClose !== null ? () => onClose() : undefined;

        let closeButtonElement : JSX.Element | null = null;
        if (closeButtonShown) {
            closeButtonElement = (
                <div className="close-modal" onClick={ handleCloseClick }>
                    <span className="bevicon bevico-close" />
                </div>
            );
        }

        const footerLeftButtonElement = (
            footerLeftButton !== null && (
                <Button
                    buttonClassName={ footerLeftButton.classes }
                    isDisabled={ footerLeftButton.isDisabled }
                    isLoading={ footerLeftButton.isLoading }
                    onClick={ footerLeftButton.onClick }
                >
                    { footerLeftButton.children }
                </Button>
            )
        );

        return (
            <Modal className={ className }>
                <div className={ `header cell-ptb-md cell-plr-md ${ headerClasses }` }>
                    <h4 className="title">{ headerText }</h4>
                    { closeButtonElement }
                </div>
                <div className={ `body ${ bodyClasses }` }>
                    { this.props.children }
                </div>
                <div className={ `footer cell-ptb-md cell-plr-md ${ footerClasses }` }>
                    <div className="col-intable">
                        <div className="cell col-xs-5 footer-left-button">
                            { footerLeftButtonElement }
                        </div>
                        <div className="cell col-xs-2 footer-label">
                            { footerLabel }
                        </div>
                        <div className="cell col-xs-5 text-right footer-right-buttons">
                            { actionElements }
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}
