import Amplify, { Auth } from "aws-amplify";
import Cookies from "js-cookie";
import "url-search-params-polyfill";
import { cookieManager } from "shared/lib/manager";

// Note: The keys for configs must match those in Environment.java
// "dev" | "staging" | "prod"
const currentEnvironment = Cookies.get("Environment");
const configs = {
  // Local/Development (shared)
  dev: {
    AMPLIFY_AUTH: {
      mandatorySignIn: true,
      region: "us-east-1",
      userPoolId: "us-east-1_Q7wUTr8lj",
      // identityPoolId: config.cognito.IDENTITY_POOL_ID,
      userPoolWebClientId: "i33ef66i1sgp2jmvfk498dvbh",
    },
    authBaseUrl: "/auth",
    authCookieDomain: "localhost",
    lambdaApiBase: "https://11u2oteuxc.execute-api.us-east-1.amazonaws.com/api",
  },

  // Production
  prod: {
    AMPLIFY_AUTH: {
      mandatorySignIn: true,
      region: "us-east-1",
      userPoolId: "us-east-1_yfLnl9Agj",
      // identityPoolId: config.cognito.IDENTITY_POOL_ID,
      // This should match user_auth/webpack/config.js configs.prod.userPoolWebClientId
      userPoolWebClientId: "31hub3vm9i6gfl6poji5c3h0tg",
    },
    authBaseUrl: "/auth",
    authCookieDomain: ".bevspot.com",
    lambdaApiBase: "https://api.bevspot.com/auth",
  },

  // Staging, etc
  staging: {
    AMPLIFY_AUTH: {
      mandatorySignIn: true,
      region: "us-east-1",
      userPoolId: "us-east-1_HX1blXNbZ",
      // identityPoolId: config.cognito.IDENTITY_POOL_ID,

      // userPoolWebClientId should match user_auth/webpack/config.js
      // See configs.staging.userPoolWebClientId
      userPoolWebClientId: "1lgr7n6iv1opl5qmetucjjka11",
    },
    authBaseUrl: "/auth",
    authCookieDomain: ".bevspot.net",
    lambdaApiBase: "https://api-staging.bevspot.net/auth",
  },
};

const currentConfig = configs[currentEnvironment];

Amplify.configure({
  Auth: {
    mandatorySignIn: currentConfig.AMPLIFY_AUTH.mandatorySignIn,
    region: currentConfig.AMPLIFY_AUTH.region,
    userPoolId: currentConfig.AMPLIFY_AUTH.userPoolId,
    userPoolWebClientId: currentConfig.AMPLIFY_AUTH.userPoolWebClientId,
  },
});
window.AMPLIFY_CONFIG = Auth.configure();
window.AMPLIFY_CONFIG.urls = {
  authBaseUrl: currentConfig.authBaseUrl,
  lambdaApiBase: currentConfig.lambdaApiBase,
};
window.AMPLIFY_CONFIG.authCookieDomain = currentConfig.authCookieDomain;

const setLocalStorageAttrs = () => {
  // Grab refresh token and user email from cookie
  // We need to store the refresh token in localStorage for Cognito
  const userPoolWebClientId = currentConfig.AMPLIFY_AUTH.userPoolWebClientId;
  const refreshToken = Cookies.get("refreshToken");
  const userEmail = Cookies.get("currentUserEmail");
  const userToken = Cookies.get("userSessionIdentifier");
  const accessToken = Cookies.get("accessToken");

  // If any of these are missing, have the user log in again
  if (!userEmail || !refreshToken || !accessToken || !userToken) {
    window.location.href = window.AMPLIFY_CONFIG.urls.authBaseUrl;
    return;
  }

  // Set data in localstorage where AWS expects it
  localStorage.setItem(`CognitoIdentityServiceProvider.${userPoolWebClientId}.LastAuthUser`, userEmail);
  localStorage.setItem(`CognitoIdentityServiceProvider.${userPoolWebClientId}.${userEmail}.refreshToken`, refreshToken);
  localStorage.setItem(`CognitoIdentityServiceProvider.${userPoolWebClientId}.${userEmail}.idToken`, userToken);
  localStorage.setItem(`CognitoIdentityServiceProvider.${userPoolWebClientId}.${userEmail}.accessToken`, accessToken);

  // Kill the unneeded cookies (we need userSessionIdentifier for auth)
  cookieManager.deleteCookie("refreshToken");
  cookieManager.deleteCookie("accessToken");
  cookieManager.deleteCookie("currentUserEmail");
};

// Users don't need to be authenticated for /drink_price_tool
if (window.location.href.indexOf("drink_price_tool") === -1) {
  Auth.currentAuthenticatedUser()
  // The user is authenticated as far as Cognito knows (localStorage)
  .then(() => {
    // Redirect accordingly.
    const urlParams = new URLSearchParams(window.location.search);
    if (window.location.href.indexOf("/login/") === -1 && urlParams.has("next")) {
      window.location.href = decodeURIComponent(urlParams.get("next"));
    }
  })
  // The user is NOT authenticated as far as Cognito knows (localStorage)
  // We will set their localStorage manually as if Cognito had done it
  .catch((err) => {
    setLocalStorageAttrs();
  });
}
