import React from 'react';

import './AlertBanner.scss';

interface IAlertBannerProps {
    readonly title? : string;
    readonly type : 'success' | 'error' | 'warning' | 'info' | 'announce' | 'needs-attention';
    readonly actionButtons? : Array<JSX.Element>;
    children : React.ReactNode;
}

export class AlertBanner extends React.Component<IAlertBannerProps, object> {
    public render() {
        const {
            title,
            type,
            actionButtons,
        } = this.props;

        return (
            <div className={ `alert-banner alert-banner-${ type }` }>
                <div className="alert-banner-indicator">
                    <span className="bevicon" />
                </div>
                <div className="alert-banner-content">
                    { title && title.length > 0 && <h5>{ title }</h5> }
                    <div className="alert-banner-message">
                        { this.props.children }
                    </div>
                    { actionButtons && actionButtons.length > 0 &&
                        <div className="alert-banner-actions">
                            { actionButtons }
                        </div>
                    }
                </div>
            </div>
        );
    }
}
