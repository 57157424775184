// @ts-nocheck
// TODO Typings for CSSTransitionGroup are not working as expected

import React from 'react';
import { connect } from 'react-redux';
import { CSSTransitionGroup } from 'react-transition-group';
import { Dispatch } from 'redux';
import { HubspotChatUtils } from 'shared/utils/hubspotChatUtils';

import { PathUtils } from 'shared/utils/pathUtils';
import { PageContext, UserSessionUtils } from './userSessionUtils';

import { UserSessionActions } from './actions';
import { ISignUpOptionsState } from './index';
import { IUserAccountRetailer, IUserAccountRetailersByGroup } from './reducers';

import { LocationId } from 'api/Location/model/LocationId';

import { Button } from 'shared/components/Button';
import { UserIcon } from 'shared/components/UserIcon';
import { HubSpotChat } from 'shared/components/HubSpotChat/HubSpotChat';

import './UserButtonApp.scss';

interface IUserButtonAppProps {
    firstName : string | null;
    lastName : string | null;
    userPopdownOpen : boolean;
    userRetailers : IUserAccountRetailersByGroup | null;
}

interface IConnectedUserButtonAppProps extends IUserButtonAppProps {
    dispatch : Dispatch<ISignUpOptionsState>;
}

export class UserButtonApp extends React.Component<IConnectedUserButtonAppProps, object> {
    private thisElement : HTMLDivElement | null = null;
    private retailerId : LocationId = new LocationId(window.GLOBAL_RETAILER_ID);

    public componentWillMount() {
        const {
            dispatch,
        } = this.props;

        window.hsConversationsSettings = {
            loadImmediately: false,
            inlineEmbedSelector: '#bevspot-hs-chat'
        };

        dispatch(UserSessionActions.fetchUserInfo());
        dispatch(UserSessionActions.determinePageContext());
    }

    public render() {
        const {
            firstName,
            lastName,
            userPopdownOpen,
        } = this.props;

        if (firstName === null || lastName === null) {
            return <div />;
        }

        return (
            <div
                className="user-button-app react-user-icon-with-popover"
                onBlur={ this.handleOnBlur }
                tabIndex={ 0 }
                ref={ this.getRef }
            >
                <span className="help-chat-nav-icon">
                    <span onClick={ this.onOpenLiveChatClick } className="bevicon bevico-chat"/>
                    <span onClick={ this.onGoToHelpCenter } className="bevicon bevico-help"/>
                </span>
                <UserIcon
                    onClick={ this.handleOnIconClick }
                    showCloseIcon={ userPopdownOpen }
                    firstName={ firstName }
                    lastName={ lastName }
                />
                { userPopdownOpen &&
                    <div className="react-user-icon-popover-arrow" />
                }
                <CSSTransitionGroup
                    transitionName="react-user-icon-popover"
                    transitionEnter={ true }
                    transitionEnterTimeout={ 0 }
                    transitionLeave={ true }
                    transitionLeaveTimeout={ 0 }
                >
                    { userPopdownOpen &&
                        <div key="popover" className="react-user-icon-popover">
                            <div className="react-user-icon-popover-context-menu">
                                { this.showHome() }
                            </div>
                        </div>
                    }
                </CSSTransitionGroup>
                <HubSpotChat/>
            </div>
        );
    }

    private showHome() {
        const {
            firstName,
            lastName,
            userRetailers,
        } = this.props;

        return (
            <React.Fragment>
                <div className="user-name cell-p-lg">
                    <h5 className="secondary-bold">{ `${firstName} ${lastName}` }</h5>
                </div>
                <div className={ `context-tab-home divider-line context-tab-body cell-p-lg` }>
                    { userRetailers !== null &&
                        <ul className="context-tab-user-retailers">
                            {
                                userRetailers.groups.map((group) => {
                                    return (
                                        <li key={ group.id } className="group">
                                            <a href={ `/group/dashboard/g/${group.id}/` } className="group-name">{ group.name }</a>
                                            { group.orderedRetailerIds.length > 0 &&
                                                <ul className="group-list">
                                                    { group.orderedRetailerIds.map((retailer) => this.generateRetailerRow(retailer)) }
                                                </ul>
                                            }
                                        </li>
                                    );
                                })
                            }
                            { userRetailers.ungrouped.map((retailer) => this.generateRetailerRow(retailer)) }
                        </ul>
                    }
                </div>
                { userRetailers !== null && userRetailers.sandboxes.length !== 0 &&
                    <div className="sandbox-container context-tab-body cell-p-lg divider-line">
                        <ul className="context-tab-user-retailers">
                            { userRetailers.sandboxes.map((retailer) => this.generateRetailerRow(retailer)) }
                        </ul>
                    </div>
                }
                <div className="context-tab-footer divider-line cell-p-lg">
                    <ul>
                        <li>
                            <a className="flex-text-with-icon middle tools-and-articles" href={ PathUtils.getAbsolutePathForRequest('/user') }>
                                <span className="icon-left bevicon bevico-user" />
                                <span>User Home</span>
                            </a>
                            <a className="flex-text-with-icon middle" href={ PathUtils.getAbsolutePathForRequest('/user/edit') }>
                                <span className="icon-left bevicon bevico-ic_settings_black_24px" />
                                <span>User Settings</span>
                            </a>
                        </li>
                    </ul>
                    <Button
                        buttonClassName="medium logout-btn"
                        isDisabled={ false }
                        isLoading={ false }
                        onClick={ this.handleOnLogoutButtonClick }
                    >
                        Log Out
                    </Button>
                </div>
            </React.Fragment>
        );
    }

    private readonly generateRetailerRow = (retailer : IUserAccountRetailer) => {
        const highlightUnselected = this.retailerId.getValue() !== retailer.id ? 'not-selected' : '';
        return (
            <li key={ retailer.id } className={ `${highlightUnselected} retailer${ retailer.isSandbox ? ' sandbox' : '' }` }>
                <div className="retailer-container flex-text-with-icon top">
                    { this.retailerId.getValue() === retailer.id &&
                        <span className="bevicon bevico-keyboard-arrow-right main-icon-left" />
                    }
                    <span className={ `${this.retailerId.getValue() !== retailer.id ? 'retailer-align' : ''} icon-left bevicon bevico-${ retailer.accountType }` } />
                    <div>
                        <a href={ `/account/dashboard/r/${retailer.id}/` }>
                            <span className={ `retailer-name ${highlightUnselected}` }>{ retailer.name }</span>
                        </a>
                        { retailer.isSandbox &&
                            <p className="cell-ptb-sm">
                                <i>
                                    Don't spoil your data — here’s a sample account to play with.
                                </i>
                            </p>
                        }
                    </div>
                </div>
            </li>
        );
    }

    private readonly getRef = (element : HTMLDivElement) => {
        this.thisElement = element;
    }

    private readonly handleOnBlur = (event : React.FocusEvent<HTMLDivElement>) => {
        // `relatedTarget` doesn't work with IE11, so we had to get creative here to work for all browsers
        const target = (event.relatedTarget as Element) || document.activeElement;
        if (this.thisElement !== null && !this.thisElement.contains(target)) {
            return this.props.dispatch(UserSessionActions.setUserPopdownOpen(false));
        }
    }

    private readonly handleOnIconClick = () => {
        const {
            userPopdownOpen,
            dispatch,
        } = this.props;

        return dispatch(UserSessionActions.setUserPopdownOpen(!userPopdownOpen));
    }

    private readonly handleOnLogoutButtonClick = () => {
        const {
            dispatch,
        } = this.props;

        return dispatch(UserSessionActions.logoutUser());
    }

    private readonly onOpenLiveChatClick = () => {
        if (window.HubSpotConversations) {
            HubspotChatUtils.openChatWidget();
        }
    }

    private readonly onGoToHelpCenter = () => {
        if (window._elev) {
            window._elev.open();
        }
    }
}

const mapStateToProps = (state : ISignUpOptionsState) : IUserButtonAppProps => ({
    firstName: state.userSession.firstName,
    lastName: state.userSession.lastName,
    userPopdownOpen: state.userSession.userPopdownOpen,
    userRetailers: state.userSession.userRetailers,
});

export const ConnectedUserButtonApp = connect<IUserButtonAppProps, object, object, ISignUpOptionsState>(mapStateToProps)(UserButtonApp);
