import { Unit } from 'api/Product/model/Unit';

import { RuntimeException } from 'shared/lib/general/exceptions/RuntimeException';

export enum MassUnit {
    GRAM = 'g',
    KILOGRAM = 'kg',
    POUND = 'lbs',
    DRY_OUNCE = 'dry_oz'
}

const unitByValue = new Map<string, MassUnit>();
Object.keys(MassUnit).forEach((key) => {
    // @ts-ignore
    unitByValue.set(MassUnit[key as any], MassUnit[key as any] as MassUnit);
});

export namespace MassUnit {
    export function isMassUnit(unit : Unit) : unit is MassUnit {
        return (typeof unitByValue.get(unit) !== 'undefined');
    }

    export function isMassUnitValue(unitValue : string) : boolean {
        return (typeof unitByValue.get(unitValue) !== 'undefined');
    }

    export function getByMassUnitValue(unitValue : string) : MassUnit {
        const unit = unitByValue.get(unitValue);

        if (typeof unit === 'undefined') {
            throw new RuntimeException('unexpected value: ' + unitValue);
        }

        return unit;
    }

    export function getUnits() : Array<MassUnit> {
        return Array.from(unitByValue.values());
    }
}
