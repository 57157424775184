import React from 'react';

import './PageHeader.scss';

export enum PageHeaderTheme {
    Default = 1,
    Dark = 2,
    BulkActions = 3,
    Announce = 4,
    Transparent = 5,
    CustomerSuccess = 6
}

interface IPageHeaderProps {
    theme : PageHeaderTheme;
    children : React.ReactNode;
}

export class PageHeader extends React.Component<IPageHeaderProps, object> {
    public render() {
        const {
            theme,
        } = this.props;

        let themeClasses : string = 'page-header';

        if (theme === PageHeaderTheme.Dark) {
            themeClasses += ' dark';
        } else if (theme === PageHeaderTheme.BulkActions) {
            themeClasses += ' dark bulk-actions';
        } else if (theme === PageHeaderTheme.Announce) {
            themeClasses += ' dark announce';
        } else if (theme === PageHeaderTheme.Transparent) {
            themeClasses += ' dark transparent';
        } else if (theme === PageHeaderTheme.CustomerSuccess) {
            themeClasses += ' dark customer-success';
        }

        // CAS TODO: once we get to a point where our page headers are more consistent or are broken down by page type (report, action, table etc.), we should move the PageTitle component content to this one
        return (
            <div className={ themeClasses }>
                { this.props.children }
            </div>
        );
    }
}
