import React from 'react';
import * as ReactDOM from 'react-dom';

import { IHexColorsByCategoryName } from 'shared/models/Charts/IHexColorsByCategoryName';
import { IValuesByDataIdAndCategoryName } from 'shared/models/Charts/IValuesByDataIdAndCategoryName';
import { HexColor } from 'shared/models/HexColor';
import { LinearScale } from 'shared/models/StackedBarChart/LinearScale';
import { OrdinalBandScale } from 'shared/models/StackedBarChart/OrdinalBandScale';
import { SvgPattern } from 'shared/models/StackedBarChart/SvgPattern';

import { ChartLegend } from './ChartLegend';
import { HorizontalBarChartGrid } from './HorizontalBarChartGrid';
import { PrintStyleYAxisHeader } from './PrintStyleYAxisHeader';
import { SvgPatternDefinitions } from './SvgPatternDefinitions';
import { additionalTextPadding, verticalLinePadding, XAxis } from './XAxis';
import { YAxisLabelContainer } from './YAxisLabelContainer';

import { AlignmentEnum, StackedBarChartTooltip } from './StackedBarChartTooltip'; // this should probably not be here?

import { utils } from 'shared/components/StackedBarGraph/utils';

import { MIN_DESKTOP_WIDTH } from 'shared/constants';

/**
 * HorizontalBarChart properties
 */
export interface IHorizontalBarChart {
    /** boolean indicating whether or not legend on top of chart should be displayed */
    showLegend : boolean;
    /** boolean indicating if the chart has a tooltip  */
    hasTooltip : boolean;
    /** boolean indicating if the chart has a vertical line following the mouse */
    hasMouseVerticalLine : boolean;

    /** colors for each category possible within a stacked bar */
    categoryColorsByCategoryName : IHexColorsByCategoryName;
    /** values for each data group (y-axis label), broken down by category */
    valuesByDataIdAndCategoryName : IValuesByDataIdAndCategoryName;

    /** function for event on mouse hover/on focus of the y-axis  */
    yAxisOnMoveOver : ((keyName : string) => void) | null;
    /** function for event on mouse leave/on blur of the y-axis  */
    yAxisOnMoveOut : ((keyName : string) => void) | null;

    /** function for event on mouse hover/on focus of a stacked bar  */
    barChartOnMoveOver : ((keyName : string) => void) | null;
    /** function for event on mouse leave/on blur of a stacked bar  */
    barChartOnMoveOut : ((keyName : string) => void) | null;

    /** pattern to use on fills for categories of bars, must be defined on the svg level */
    svgPattern : SvgPattern;

    /** boolean indicating if tick labels on the XAxis should be shown. If this and showVerticalGridLines are both false, no x-axis will be shown */
    showXAxisTicks : boolean;
    /** boolean indicating if vertial grid lines should be shown. If this and showXAxisTicks are both false, no x-axis will be shown */
    showVerticalGridLines : boolean;

    /** function for formatting labels on the x-axis (also used on the tooltip, if applicable) */
    formatTicks : ((value : number) => string | number) | null;

    /**
     * color to be shown if 1) the bar chart only shows category colors on hover/focus, and needs a default display
     * or 2) if a category does not have a set color
     */
    barColorToOverwriteCategoryColors : HexColor;

    /**
     * height of an individual bar on the chart, in pixels. Together with paddingBetweenBars used to calculate the overall height of the chart's svg
     * (note: if bar height < text size, there will be display issues)
     */
    barHeight : number;
    /** padding between bars on the chart, in pixels. Together with barHeight used to calculate the overall height of the chart's svg */
    paddingBetweenBars : number;

    /** boolean indicating if the chart's page is currently loading data, and therefore should show a default view */
    isLoading : boolean;
}

interface IHorizontalBarChartState {
    showCategoryColors : {[key : string] : boolean};
    width : number;
    legendTextElement : Element | null;
    tooltipElement : JSX.Element | null;
    verticalMouseLine : JSX.Element | null;
    isPrintView : boolean;
}

const loadingState = {
    showLegend: false,
    hasTooltip: false,
    hasMouseVerticalLine: false,
    categoryColorsByCategoryName: { test: new HexColor('#e9e9e6') },
    valuesByDataIdAndCategoryName: {
        '----------------------------------'    : { test: 15 },
        '-----------------------------------'   : { test: 13 },
        '------------------------------------'  : { test: 9 },
        '-------------------------------------' : { test: 7 },
        '--------------------------------------': { test: 3 },
    },
    yAxisOnMoveOver: null,
    yAxisOnMoveOut: null,
    barChartOnMoveOver: null,
    barChartOnMoveOut: null,
    svgPattern: SvgPattern.SOLID,
    showXAxisTicks: false,
    showVerticalGridLines: false,
    formatTicks: null,
    barColorToOverwriteCategoryColors: new HexColor('#e9e9e6'),
    barHeight: 20,
    paddingBetweenBars: 5,
};

/**
 * Form HorizontalBarChart
 */
export class HorizontalBarChart extends React.Component<IHorizontalBarChart, IHorizontalBarChartState> {
    private xScale : LinearScale | null = null;
    private yScale : OrdinalBandScale | null = null;
    private chartGridHeight : number = 0;
    private chartGridWidth : number = 0;
    private barChartComponentSvg : SVGElement | null = null;
    private isMobile : boolean = false;

    constructor(props : IHorizontalBarChart) {
        super(props);
        this.state = {
            showCategoryColors: {},
            width: 0,
            tooltipElement: null,
            legendTextElement: null,
            verticalMouseLine: null,
            isPrintView: false,
        };
    }

    // This functionality is to handle window resizing -- if a parent generic component is created for HorizontalBarChart, the resize should live there
    public handleResize = () => {
        const thisNode = ReactDOM.findDOMNode(this);

        if (thisNode) {
            const container = thisNode.parentNode as HTMLDivElement;

            setTimeout(() => {
                if (container) {
                    const containerWidth = container.clientWidth || container.offsetWidth;
    
                    // NOTE: `this` can be used because of the arrow function
                    this.setState({
                        ...this.state,
                        width: containerWidth,
                    });
                }
            }, 350);
        }
    }

    public hasClass = (elementClassName : string, classString : string) => {
        return (' ' + elementClassName + ' ').indexOf(' ' + classString + ' ') > -1;
    }

    public handleTooltipEnter = (displayName : string, categoryFocus : string, xValueMouse : number | null, isMobile : boolean) : JSX.Element => {
        const {
            categoryColorsByCategoryName,
            valuesByDataIdAndCategoryName,
            svgPattern,
            barHeight,
            formatTicks,
            barColorToOverwriteCategoryColors,
        } = this.props;

        if (categoryFocus !== 'Total' && !(categoryFocus in valuesByDataIdAndCategoryName[displayName])) {
            throw Error('invalid focus for tooltip');
        }

        // TODO should just check if there are two categories instead of checking if one? depends on what sizing is
        const isSingleCategoryTooltip : boolean = (Object.keys(categoryColorsByCategoryName).length === 1);
        const isVerticalTooltip : boolean = (Object.keys(categoryColorsByCategoryName).length > 2);
        const tooltipScale : number = isMobile ? 0.75 : 0.45;

        let tooltipWidth : number;
        if (isSingleCategoryTooltip) {
            tooltipWidth = Math.min(Math.max(this.chartGridWidth * tooltipScale, 210), 224);
        } else if (isVerticalTooltip) {
            tooltipWidth = Math.min(Math.max(this.chartGridWidth * tooltipScale, 210), 224);
        }  else if (isMobile) {
            tooltipWidth = this.chartGridWidth * tooltipScale;
        } else {
            tooltipWidth = Math.min(Math.max(this.chartGridWidth * tooltipScale, 308), 420);
        }

        let yValue : number = 0;
        let xValueTooltip : number = xValueMouse ? (xValueMouse + 20) : 0;
        if (this.xScale) {
            if (categoryFocus === 'Total') {
                xValueTooltip = this.chartGridWidth;
            }

            if ((xValueTooltip + tooltipWidth) > this.chartGridWidth) {
                xValueTooltip = this.chartGridWidth - tooltipWidth;
            }
        }

        let alignment : AlignmentEnum = AlignmentEnum.TOP;
        if (this.yScale) {

            const chartHeightWithXAxis : number =  this.chartGridHeight + verticalLinePadding + additionalTextPadding;

            const rowYValue : number = this.yScale.getValueFromKey(displayName) || 0;

            let barHeightAdjustment : number = barHeight;
            if (categoryFocus === 'Total' && this.xScale) { // if hover on yAxis and there is space to put the tooltip to the right of the bar, put tooltip at the height of the bar
                // get total X value of the specific bar
                const xValueForEndOfBar : number = utils.getMaximumTotalCategoryValueFromDataValuesObject({ displayName: valuesByDataIdAndCategoryName[displayName] });

                if ((utils.scaleValueLinear(xValueForEndOfBar, this.xScale) + tooltipWidth) <= this.chartGridWidth) {
                    barHeightAdjustment = 0;
                }

            }

            if (rowYValue > (chartHeightWithXAxis / 2)) {
                yValue = chartHeightWithXAxis - (rowYValue - barHeightAdjustment) + 5; // 5 px of padding
                alignment = AlignmentEnum.BOTTOM;
            } else {
                yValue = rowYValue + barHeightAdjustment + 5;
            }

        }

        return (
            <StackedBarChartTooltip
                displayTitle={ displayName }
                valuesByCategoryName={ valuesByDataIdAndCategoryName[displayName] }
                perCategoryColorsByCategoryId={ categoryColorsByCategoryName }
                yValue={ yValue }
                xValue={ xValueTooltip }
                categoryFocus={ categoryFocus }
                svgPattern={ svgPattern }
                squareSize={ 13 } // This is the best course of action for now, given that the tooltip is not statically set on the page
                alignment={ alignment }
                formatNumbers={ formatTicks }
                defaultColor={ barColorToOverwriteCategoryColors }
            />
        );
    }

    public handleYAxisTooltipEnter = (displayName : string) => {

        const {
            yAxisOnMoveOver,
        } = this.props;

        const newTooltipElement = this.handleTooltipEnter(displayName, 'Total', null, this.isMobile);
        this.setState({
            ...this.state,
            tooltipElement: newTooltipElement,
        });

        if (yAxisOnMoveOver) {
            yAxisOnMoveOver(displayName);
        }
    }

    public handleTooltipLeave = (displayName : string) => {
        const {
            yAxisOnMoveOut,
        } = this.props;

        this.setState({
            ...this.state,
            tooltipElement: null,
        });

        // call additional mouse events for y-axis if necessary
        if (yAxisOnMoveOut) {
            yAxisOnMoveOut(displayName);
        }

    }

    public handleTooltipClickSvg = (event : React.MouseEvent<SVGElement>) => {
        const {
            hasTooltip,
        } = this.props;

        // only have click events on mobile devices
        if (this.isMobile) {
            if (hasTooltip) {
                const target = event.target as SVGElement;
                if (this.hasClass(target.className.baseVal, 'bar-chart-category-stack')) {

                    // use parentNode as a fallback because IE does not support parentElement
                    const stackParent = target.parentElement || target.parentNode as HTMLElement;
                    if (!stackParent) {
                        throw Error('no HorizontalStackedBar found for StackedBarCategoryStack');
                    }

                    const rect = target.getBoundingClientRect();
                    const offsetX = rect.left + document.body.scrollLeft;

                    let x = (event.pageX - offsetX); // x value of mouse relative to the object it's on

                    const parentOffsetX = stackParent.getBoundingClientRect().left; // need scrolling?

                    // if the mouse is on a stacked bar, the mouse value relative to the chart is the page mouse value - stackedBar (event.target parent) offset,
                    // instead of page mouse value - event.target offset
                    x = x + (offsetX - parentOffsetX);

                    const groupName : string | null = stackParent.getAttribute('data-group-name');

                    if (groupName === null) {
                        throw Error('group name not set on HorizontalStackedBar');
                    }

                    const categoryFocus : string | null = target.getAttribute('data-category');
                    if (categoryFocus === null) {
                        throw Error('StackedBarCategoryStack has no category attribute');
                    }

                    const newTooltipElement = this.handleTooltipEnter(groupName, categoryFocus, x, true);

                    this.setState({
                        ...this.state,
                        tooltipElement: newTooltipElement,
                    });

                } else {
                    this.setState({
                        ...this.state,
                        tooltipElement: null,
                    });
                }
            }
        }

    }

    /**
     * In order to have mouse move events for both the tooltip and vertical mouse line, they must be done
     * on the same level. The vertical line needs to happen on the level of the svg, so they are both done in
     * this component, and information about specific bar stacks is derived from the event target.
     */
    public handleMouseMoveSvg = (event : React.MouseEvent<SVGElement>) => {
        const {
            hasMouseVerticalLine,
            hasTooltip,
            formatTicks,
        } = this.props;

        if (this.xScale) {
            const parentSVG = event.currentTarget;
            const target = event.target as SVGElement;

            const rect = parentSVG.getBoundingClientRect();
            const offsetX = rect.left + document.body.scrollLeft;

            let x = (event.pageX - offsetX); // x value of mouse relative to the object it's on

            // Need to check if x is past actual chart width (may still be on svg)
            if (x > this.chartGridWidth) {
                this.setState({
                    ...this.state,
                    verticalMouseLine: null,
                });
            } else {

                let newTooltipElement = this.state.tooltipElement;
                let newMouseVertical = this.state.verticalMouseLine;

                if (hasTooltip) {
                    if (this.hasClass(target.className.baseVal, 'bar-chart-category-stack')) {
                        // use parentNode as a fallback because IE does not support parentElement
                        const stackParent = target.parentElement || target.parentNode as HTMLElement;

                        if (!stackParent) {
                            throw Error('no HorizontalStackedBar found for StackedBarCategoryStack');
                        }

                        const parentOffsetX = stackParent.getBoundingClientRect().left; // need scrolling?

                        // if the mouse is on a stacked bar, the mouse value relative to the chart is the page mouse value - stackedBar (event.currentTarget parent) offset,
                        // instead of page mouse value - event.currentTarget offset
                        x = x + (offsetX - parentOffsetX);

                        const groupName : string | null = stackParent.getAttribute('data-group-name');

                        if (groupName === null) {
                            throw Error('group name not set on HorizontalStackedBar');
                        }

                        const categoryFocus : string | null = target.getAttribute('data-category');
                        if (categoryFocus === null) {
                            throw Error('StackedBarCategoryStack has no category attribute');
                        }

                        newTooltipElement = this.handleTooltipEnter(groupName, categoryFocus, x, false);
                    }
                }

                if (hasMouseVerticalLine) {
                    const chartHeightWithXAxis : number = this.chartGridHeight + verticalLinePadding + additionalTextPadding;
                    const invertedXValue = utils.invertScaledValue(x, this.xScale);

                    newMouseVertical = (
                        <svg
                            className="bar-chart-vertical-hover"
                        >
                            <g
                                style={ { overflow: 'hidden', textAnchor: 'middle', fontWeight: 'bold', textShadow: '1px 1px 1px #FFFFFF' } }
                            >
                                <line
                                    x1={ x }
                                    x2={ x }
                                    y1={ 0 }
                                    y2={ this.chartGridHeight + verticalLinePadding } // matches XAxis styling
                                    stroke="black"
                                    strokeDasharray="5,5"
                                />
                                <rect
                                    x={ x - 20 }
                                    y={ chartHeightWithXAxis - 15 }
                                    fill="#ffffff"
                                    height="20"
                                    width="40"
                                />
                                <text
                                    x={ x }
                                    y={ chartHeightWithXAxis }
                                    textAnchor="middle"
                                    fill="black"
                                >
                                    { formatTicks ? formatTicks(invertedXValue) : invertedXValue.toFixed(1) }
                                </text>
                            </g>
                        </svg>
                    );
                }

                this.setState({
                    ...this.state,
                    verticalMouseLine: newMouseVertical,
                    tooltipElement: newTooltipElement,
                });
            }

        }
    }

    public handleMouseLeaveSvg = () => {
        this.setState({
            ...this.state,
            verticalMouseLine: null,
            tooltipElement: null,
        });
    }

    public barChartSvgRef = (instance : SVGElement | null) => {
        this.barChartComponentSvg = instance;
    }

    // TODO: event as `any` here is weak
    public onBarChartPageClick = (event : any) => {
        // ie11 doesn't support `contains` on <svg/>'s
        if (this.barChartComponentSvg !== null && typeof this.barChartComponentSvg.contains === 'undefined') {
            return;
        }
        if (this.barChartComponentSvg === null || !this.barChartComponentSvg.contains(event.target)) {
            this.setState({
                ...this.state,
                verticalMouseLine: null,
                tooltipElement: null,
            });
        }
    }

    public handleChartPrint = (printSelected : boolean) => {
        this.setState({
            ...this.state,
            isPrintView: printSelected,
        });
    }

    public componentDidMount() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);

        if (this.isMobile) {
            // handle on capture phase so that clicks on y-axis labels display tooltips
            window.addEventListener('click', this.onBarChartPageClick, true);
        }

        const mediaQueryList = window.matchMedia('print');
        mediaQueryList.addListener((mql : MediaQueryListEvent) => {
            if (mql.matches) {
                this.handleChartPrint(true);
            } else {
                this.handleChartPrint(false);
            }
        });
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener('click', this.onBarChartPageClick);
    }

    public render() {
        const {
            showLegend,
            hasTooltip,
            hasMouseVerticalLine,
            categoryColorsByCategoryName,
            valuesByDataIdAndCategoryName,
            yAxisOnMoveOver,
            yAxisOnMoveOut,
            barChartOnMoveOver,
            barChartOnMoveOut,
            svgPattern,
            showXAxisTicks,
            showVerticalGridLines,
            formatTicks,
            barColorToOverwriteCategoryColors,
            barHeight,
            paddingBetweenBars,
        } = (this.props.isLoading) ? loadingState : this.props;

        const isLoading = this.props.isLoading;

        this.isMobile = window.matchMedia('(max-width: ' + MIN_DESKTOP_WIDTH + 'px)').matches;

        /**
         * On print, the y-axis labels take up 2 lines instead of 1 -- total height right now being 30px. In order to properly set the labels and bars,
         * calculate correctBarPadding and then set the label padding based on that and labelSizeOnPrint
         */
        let correctBarPadding = paddingBetweenBars;
        const labelSizeOnPrint = 30;
        if (this.state.isPrintView) {
            correctBarPadding = Math.max(41 - barHeight, paddingBetweenBars);
        }

        /**
         * Anna Lee Barber 2/8/17
         * Note on looping over valuesByDataIdAndCategoryName and its sub-categories:
         * Browsers generally enumerate object keys by insertion order, though there are two important exceptions:
         *  1: If some keys are strings and some are numbers or strings of numbers (both 1 and '1'), some browsers will enumerate
         *      all of the number keys, then all of the string keys, while others keep insertion order
         *  2: If a key is deleted and then redefined, some browsers treat that key as being in its original order, and some browsers put it at the end of the order
         * If either of these are a concern, refactor to use arrays instead
         */
        const dataKeys : Array<string> = Object.keys(valuesByDataIdAndCategoryName);

        const maxXValue : number = utils.getMaximumTotalCategoryValueFromDataValuesObject(valuesByDataIdAndCategoryName);

        const scaledWidth = (this.state.width === 0 && isLoading) ? 300 : this.state.width;

        /** if one of these changes, the other needs to change so that the total = 100%, and svgChartWidth corresponds to barChartGridWidthPercentage */
        const yAxisWidthPercentage : string = '22%';
        const barChartGridWidthPercentage : string = '78%';
        const svgChartWidth = scaledWidth * 0.78;

        const svgChartHeight : number = dataKeys.length * (barHeight + correctBarPadding) + 50;

        // Chart grid must be slightly smaller than the parent svg so that labels on the x-axis will not be cut off
        this.chartGridHeight = svgChartHeight - 50;
        this.chartGridWidth = svgChartWidth - 50;

        // if we want a negative (left) bar chart, need to calculate minXValue while maxXValue is 0
        this.xScale = utils.getLinearScale(0, maxXValue, this.chartGridWidth);
        this.yScale = utils.getOrdinalScale(dataKeys, 0, this.chartGridHeight);

        // TODO: what rule should we use for this?
        const tickCount = Math.round(this.chartGridWidth / 100);

        let containerLegendTextSize : number = 14;
        if (this.state.legendTextElement) {
            containerLegendTextSize = parseInt(window.getComputedStyle(this.state.legendTextElement).getPropertyValue('font-size'), 10);
        }
        const legendSquareSize = containerLegendTextSize;

        return (
            <div
                style={ {
                    width: '100%',
                    position: 'relative',
                } }
                className={ isLoading ? 'bar-chart-loading-state' : '' }
            >
                { (scaledWidth > 0 || isLoading) && // without this, rectangles will be calculated with negative heights & widths, which throws warnings
                    <div>
                        { showLegend &&
                            <div className="col-row cell-ptb">
                                <div
                                    className={ (Object.keys(categoryColorsByCategoryName).length > 1) ? 'col-xs-1 chart-with-y-axis-categories chart-legend-y-axis-placeholder' : 'col-xs-1 chart-legend-y-axis-placeholder' }
                                    style={ { width: yAxisWidthPercentage } }
                                >
                                    { (Object.keys(categoryColorsByCategoryName).length > 1) &&
                                        <PrintStyleYAxisHeader
                                            categoryColorsByCategoryName={ categoryColorsByCategoryName }
                                        />
                                    }
                                </div>
                                <div
                                    className={ (Object.keys(categoryColorsByCategoryName).length > 1) ? 'col-xs-11 text-right chart-with-y-axis-categories bar-chart-legend-wrapper' : 'col-xs-11 text-right bar-chart-legend-wrapper' }
                                    style={ { width: barChartGridWidthPercentage } }
                                >
                                    <div
                                        style={ { width: this.chartGridWidth } }
                                    >
                                        <ChartLegend
                                            categoryColorsByCategoryName={ categoryColorsByCategoryName }
                                            legendSquareSize={ legendSquareSize }
                                            svgPattern={ svgPattern }
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="col-row">
                            <div
                                className="chart-loading-text"
                            >
                                <h4>
                                    Not enough data yet.
                                </h4>
                                <p>
                                    Start taking some actions so you can see the good stuff.
                                </p>
                            </div>
                            <div
                                className={ (Object.keys(categoryColorsByCategoryName).length > 1) ? 'col-xs-1 chart-with-y-axis-categories y-axis-wrapper' : 'col-xs-1 y-axis-wrapper' }
                                style={ { width: yAxisWidthPercentage, padding: 0 } }
                            >
                                <YAxisLabelContainer
                                    valuesByDataIdAndCategoryName={ valuesByDataIdAndCategoryName }
                                    mouseOutEvent={ (hasTooltip ? this.handleTooltipLeave : yAxisOnMoveOut) }
                                    mouseOverEvent={ (hasTooltip ? this.handleYAxisTooltipEnter : yAxisOnMoveOver) }
                                    rowHeight={ this.yScale.getBandwidth() }
                                    paddingBetweenBars={ (this.state.isPrintView ? (barHeight + correctBarPadding - labelSizeOnPrint) : paddingBetweenBars) }
                                    formatNumbers={ formatTicks }
                                />
                            </div>
                            <div
                                className={ (Object.keys(categoryColorsByCategoryName).length > 1) ? 'col-xs-11 chart-with-y-axis-categories bar-chart-wrapper' : 'col-xs-11 bar-chart-wrapper' }
                                style={ { width: barChartGridWidthPercentage, padding: 0 } }
                            >
                                <svg
                                    className="bar-chart-svg"
                                    height={ svgChartHeight }
                                    width={ svgChartWidth }
                                    onMouseMove={ (hasTooltip || hasMouseVerticalLine) ? this.handleMouseMoveSvg : undefined }
                                    onMouseOut={ (hasTooltip || hasMouseVerticalLine) ? this.handleMouseLeaveSvg : undefined }
                                    onClick={ hasTooltip ? this.handleTooltipClickSvg : undefined }
                                    ref={ this.barChartSvgRef }
                                >
                                    <SvgPatternDefinitions
                                        svgPattern={ svgPattern }
                                    />

                                    <g>
                                        { (showXAxisTicks || showVerticalGridLines) &&
                                            <XAxis
                                                xScale={ this.xScale }
                                                showTickLabels={ showXAxisTicks }
                                                showGridLines={ showVerticalGridLines }
                                                formatTicks={ formatTicks }
                                                tickCount={ tickCount }
                                                chartGridHeight={ this.chartGridHeight }
                                            />
                                        }
                                        <HorizontalBarChartGrid
                                            chartValuesByDataIdAndCategoryName={ valuesByDataIdAndCategoryName }
                                            perCategoryColorsByCategoryId={ categoryColorsByCategoryName }
                                            barHeight={ this.yScale.getBandwidth() - correctBarPadding }
                                            xScale={ this.xScale }
                                            yScale={ this.yScale }
                                            barDefaultColor={ barColorToOverwriteCategoryColors }
                                            handleMouseOut={ barChartOnMoveOut }
                                            handleMouseOver={ barChartOnMoveOver }
                                            showCategoryColorsByGroupName={ this.state.showCategoryColors }
                                        />
                                    </g>
                                    { hasMouseVerticalLine &&
                                        this.state.verticalMouseLine
                                    }
                                </svg>
                                { hasTooltip &&
                                    this.state.tooltipElement
                                    // TODO: revisit having this not live on the state
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }

}
