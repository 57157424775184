import { IWorkSheetRowJSON } from 'apps/ProductUploader/reducers';
import React from 'react';
import '../css/SpreadsheetViewer.scss';
import { fileReaderUtils } from 'shared/utils/fileReaderUtils';

export interface ISpreadsheetViewerProps {
    readonly content : string | ArrayBuffer;
    readonly hiddenColumnHeaders? : Array<string> | undefined;
    readonly columnOrder? : Array<string> | undefined;
    readonly onClose : (() => void) | null;
    readonly filePath : string;
}

export const SUPPORTED_SPREADSHEET_FILE_TYPES = ['csv', 'xls', 'xlsx', 'xlsm', 'xlsb'];

export class SpreadsheetViewer extends React.Component<ISpreadsheetViewerProps, object> {
    public render() {
        const {
            content,
            hiddenColumnHeaders,
            filePath,
            columnOrder,
            onClose
        } = this.props;

        const dataArray = fileReaderUtils.getParsedSpreadsheetData(content);
        // Sort and Filter columns based on props
        let headerInformation : Array<{key: string, value: string}> = [];
        if (dataArray.length > 0) {
            Object.keys(dataArray[0]).forEach((key) => {
                if (!hiddenColumnHeaders || (hiddenColumnHeaders && !hiddenColumnHeaders.includes(dataArray[0][key]))) {
                    headerInformation.push({ key, value: dataArray[0][key]});
                }
            });
        }
        if (columnOrder) {
            headerInformation = headerInformation.sort((a, b) => {
                const indexOfA = columnOrder.indexOf(a.value);
                const indexOfB = columnOrder.indexOf(b.value);
                return (indexOfA === -1 ? Number.MAX_VALUE : indexOfA) - (indexOfB === -1 ? Number.MAX_VALUE : indexOfB);
            });
        }
        const rows : Array<JSX.Element> = [];

        dataArray.forEach((rowData : IWorkSheetRowJSON, rowIdx : number) => {
            const rowCols : Array<JSX.Element> = [];
            headerInformation.forEach((header, colIdx) => {
                rowCols.push(
                    <div className="cell ellipsis-out spreadsheet-col" title={ rowData[header.key] } key={`cell${colIdx}-${rowIdx}`}>
                        { rowData[header.key] }
                    </div>
                );
            });

            if (rowCols.length > 0) {
                rows.push((
                    <div className={"col-row col-row-static spreadsheet-row " + (rowIdx === 0 ? 'spreadsheet-header' : '')} key={'row' + rowIdx}>
                        { rowCols }
                    </div>
                ));
            }
        });

        return (
            <div className="spreadsheet-viewer">
                <div className={"spreadsheet-viewer-close spreadsheet-viewer-btn " + (onClose ? '' : 'hidden')} onClick={ onClose ? onClose : undefined }>
                    <span className="bevicon react-viewer-icon-close" />
                </div>
                <div className="spreadsheet">
                    <p>{ filePath }</p>
                    { rows }
                </div>
            </div>
        );
    }
}
