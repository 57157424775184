import React from 'react';

import { Distributor } from 'api/Distributor/model/Distributor';
import { Product } from 'api/Product/model/Product';
import { PackagingUtils } from 'api/Product/utils/PackagingUtils';

import { Flex } from 'shared/components/FlexLayout/Flex';
import { Popover } from 'shared/components/Popover/Popover';
import { BrandAndName } from 'shared/components/Product/BrandAndName';
import { FormatMonetaryValueWithCents } from 'shared/utils/FormatMonetaryValue';

import './InfoPopover.scss';
import { ProductCost } from 'api/Product/model/ProductCost';
import { productCostUtils } from 'api/Product/utils/productCostUtils';
import { StringValueMap } from 'api/Core/StringValueMap';
import { ProductId } from 'api/Product/model/ProductId';

export interface IInfoPopoverProps {
    product : Product;
    distributor : Distributor | null;
    productInfoIsShown : boolean;
    setProductInfoIsShown : (isShown : boolean) => void;
    onFullDetailsClick : (() => void) | null;
    vendorIsHidden? : boolean; // not using this as a future pattern. Only being used as a temporary solution for hiding vendor.
    productCostsByProductId : StringValueMap<ProductId, ProductCost>;
    productId : ProductId;
    children : React.ReactNode;
}

export class InfoPopover extends React.Component<IInfoPopoverProps, object> {

    public render() {
        const {
            vendorIsHidden,
            setProductInfoIsShown,
            productInfoIsShown,
            product,
            distributor,
            children,
            onFullDetailsClick,
            productCostsByProductId,
            productId
        } = this.props;

        const hasNote = product.getNote() !== null && product.getNote().length > 0;
        const noteText = product.getNote();
        const vendorText = distributor !== null ? distributor.getName() : 'Other'; // TO DO need to add purchasing option vendors and if multiple then needs to say 'Multiple Vendors'
        const packagingData = product.getPackagingsAndMappings().getNonDeletedPackagingData();
        const hasNoteClass = hasNote ? 'product-has-note' : '';
    
        const productCost = productCostsByProductId.get(productId);
        let productCostString : string = '';
        if (productCost) {
            const baseUnit = product.getPackagingsAndMappings().getConversions().getBaseUnit();
            const productQuantityUnitDisplayText = PackagingUtils.getPackagingDisplayTextForProductQuantityUnit(product.getPackagingsAndMappings(), baseUnit, false);
            const costDisplayInfo = productCostUtils.getCostInUnit(productCost, baseUnit, product.getPackagingsAndMappings());
            productCostString = `${ FormatMonetaryValueWithCents(costDisplayInfo) } / ${ productQuantityUnitDisplayText }`;
        }

        return (
            <Popover
                className="product-info-popover"
                preferredPositionArray={ ['below', 'above', 'left', 'right'] }
                showOnHover={ true }
                popoverContentIsShown={ productInfoIsShown }
                setPopoverContentIsShown={ setProductInfoIsShown }
                anchorContent={ (
                    <div className={ `product-info-anchor ellipsis-out cursor-pointer ${ hasNoteClass }` }>
                        { children }
                    </div>
                ) }
                popoverContent={ (
                    <React.Fragment>
                        <Flex direction="row" className="product-info-header dark" align="end">
                            <Flex direction="column" grow={ 1 }>
                                <BrandAndName
                                    brand={ product.getBrand() }
                                    name={ product.getName() }
                                />
                            </Flex>
                        </Flex>
                        <div className="product-info-container">
                            <div className="product-info-row">
                                <div className="product-info-label">
                                        Packagings
                                    </div>
                                <div className="product-info-value ellipsis-out">
                                    {
                                        packagingData.map((data, index) => {
                                            return (
                                                <div key={ `${ index } ${ data.packaging.getPackagingId() } ` }>
                                                    { PackagingUtils.getDisplayTextForPackaging(data.packaging, true) }
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                            <div className="divider-line"/>
                            { !vendorIsHidden && !window.GLOBAL_FEATURE_ACCESS.multi_vendor &&
                                <div className="product-info-row">
                                    <div className="product-info-label">
                                        Vendor
                                    </div>
                                    <div className="product-info-value ellipsis-out">
                                        { vendorText }
                                    </div>
                                </div>
                            }
                            <div className="product-info-row">
                                <div className="product-info-label">
                                    Category
                                </div>
                                <div className="product-info-value ellipsis-out">
                                    { product.getProductCategoryId() }
                                </div>
                            </div>
                            <div className="product-info-row">
                                <div className="product-info-label">
                                    Subcategory
                                </div>
                                <div className="product-info-value ellipsis-out">
                                    { product.getProductType() }
                                </div>
                            </div>
                            <div className="product-info-row">
                                <div className="product-info-label">
                                    Reporting Unit
                                </div>
                                <div className="product-info-value ellipsis-out">
                                    { PackagingUtils.getPackagingDisplayTextForProductQuantityUnit(product.getPackagingsAndMappings(), product.getPreferredReportingUnit(), false) }
                                </div>
                            </div>
                            { productCost &&
                                <div className="product-info-row">
                                    <div className="product-info-label">
                                        Cost
                                    </div>
                                    <div className="product-info-value ellipsis-out">
                                        { productCostString }
                                    </div>
                                </div>
                            }
                            { hasNote &&
                                <div className="prodict-info-row">
                                    <div className="divider-line"/>
                                    <div className="product-info-label">
                                        Note
                                    </div>
                                    <div className="product-info-value product-info-note">
                                        { noteText }
                                    </div>
                                </div>
                            }
                            { onFullDetailsClick !== null &&
                                <div className="full-details-link-container">
                                    <a onClick={ onFullDetailsClick }>
                                        See full details
                                    </a>
                                </div>
                            }
                        </div>
                    </React.Fragment>
                ) }
            />
        );
    }
}
