import React from 'react';

export interface IRenderOnlyFirstChildProps {
    children : React.ReactNode;
}

export class RenderOnlyFirstChild extends React.Component<IRenderOnlyFirstChildProps, object> {
    public render() {
        const childrenArray = React.Children.toArray(this.props.children);
        const firstChild : React.ReactNode = childrenArray[0];

        if ((typeof firstChild === 'string') || (typeof firstChild === 'number') || (typeof firstChild === 'undefined')) {
            return null;
        }

        return firstChild;
    }
}
