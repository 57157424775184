import { Action } from 'redux';

import { StringValueMap } from 'api/Core/StringValueMap';
import { StringValueSet } from 'api/Core/StringValueSet';
import { StorageArea } from 'api/InventoryCount/model/StorageArea';
import { StorageAreaId } from 'api/InventoryCount/model/StorageAreaId';
import { Product } from 'api/Product/model/Product';
import { ProductId } from 'api/Product/model/ProductId';

import { IColumnSorting } from 'shared/components/SortableColumnHeader';
import { GroupByOption } from 'shared/models/GroupByOption';
import { SortDirection } from 'shared/models/SortDirection';

import { ActionTypes, UnassignedItemsActionInterfaces } from 'apps/InventoryCount/actions/UnassignedItemsActions';
import { DropdownRow } from 'apps/InventoryCount/components/UnassignedItems/UnassignedItemsView';

export type ComponentName = 'allProductsMoreOptions' | 'groupByOptionsDropdown';

export interface IUnassignedItemsState {
    readonly visibleDropdownRow : DropdownRow | null;
    readonly isShownByComponentName : { [componentName in ComponentName] : boolean };
    readonly shownMoreOptionsProductIds : StringValueSet<ProductId>;
    readonly selectedProductIds : StringValueSet<ProductId>;

    // data based on props (putting here for sorting/grouping/filtering purposes)
    readonly productIdsForDisplay : StringValueSet<ProductId>;
    readonly productsById : StringValueMap<ProductId, Product>;
    readonly storageAreaIdSetByProductId : StringValueMap<ProductId, StringValueSet<StorageAreaId>>;
    readonly storageAreasById : StringValueMap<StorageAreaId, StorageArea>;
    readonly searchTerm : string | null;

    // Table UI
    readonly tableSorting : IColumnSorting;
    readonly activeGroupByOption : GroupByOption;
    readonly sortedGroupNamesToDisplay : Array<string>;
    readonly sortedProductIdsToDisplayByGroupName : {[groupName : string] : Array<ProductId>};
    readonly panelIsOpenByGroupNameFromUserInput : {[groupName : string] : boolean};
    readonly panelIsOpenByGroupNameFromFiltering : {[groupName : string] : boolean};
    readonly defaultPanelIsOpen : boolean;
}

const unassignedItemsInitialState : IUnassignedItemsState = {
    isShownByComponentName: {
        allProductsMoreOptions: false,
        groupByOptionsDropdown: false,
    },
    visibleDropdownRow: null,
    shownMoreOptionsProductIds: new StringValueSet<ProductId>(),
    selectedProductIds: new StringValueSet<ProductId>(),

    productIdsForDisplay: new StringValueSet<ProductId>(),
    productsById: new StringValueMap(),
    storageAreaIdSetByProductId: new StringValueMap(),
    storageAreasById: new StringValueMap(),
    searchTerm: null,

    tableSorting: {
        sortedBy: 'ITEM_NAME',
        direction: SortDirection.ASCENDING,
    },
    activeGroupByOption: GroupByOption.LAST_EDITED,
    sortedGroupNamesToDisplay: [],
    sortedProductIdsToDisplayByGroupName: {},
    panelIsOpenByGroupNameFromUserInput: {},
    panelIsOpenByGroupNameFromFiltering: {},
    defaultPanelIsOpen: true,
};

const reduceSetDefaultPanelIsOpen = (
    state : IUnassignedItemsState,
    action : UnassignedItemsActionInterfaces.ISetDefaultPanelIsOpen
) : IUnassignedItemsState => {
    return {
        ...state,
        defaultPanelIsOpen: action.payload.defaultPanelIsOpen,
        panelIsOpenByGroupNameFromUserInput: {},
        panelIsOpenByGroupNameFromFiltering: {},
    };
};

const reduceSetVisibleDropdownRow = (
    state : IUnassignedItemsState,
    action : UnassignedItemsActionInterfaces.ISetVisibleDropdownRow,
) : IUnassignedItemsState => {
    const {
        visibleDropdownRow,
    } = action.payload;

    return {
        ...state,
        visibleDropdownRow,
    };
};

const reduceSetMoreOptionsIsShownForProductIds = (
    state : IUnassignedItemsState,
    action : UnassignedItemsActionInterfaces.ISetMoreOptionsIsShownForProductIds
) : IUnassignedItemsState => {
    // Enforces constraint that only one can be open at a time
    let newShownMoreOptionsProductIds : StringValueSet<ProductId>;
    if (action.payload.isShown) {
        newShownMoreOptionsProductIds = new StringValueSet<ProductId>(action.payload.productIds);
    } else {
        newShownMoreOptionsProductIds = new StringValueSet();
    }

    return {
        ...state,
        shownMoreOptionsProductIds: newShownMoreOptionsProductIds,
    };
};

const reduceSetComponentIsShown = (
    state : IUnassignedItemsState,
    action : UnassignedItemsActionInterfaces.ISetComponentIsShown
) : IUnassignedItemsState => {
    return {
        ...state,
        isShownByComponentName: {
            ...state.isShownByComponentName,
            [action.payload.componentName]: action.payload.isShown,
        },
    };
};

const reduceSetSelectedProductIds = (
    state : IUnassignedItemsState,
    action : UnassignedItemsActionInterfaces.ISetSelectedProductIds
) : IUnassignedItemsState => {
    return {
        ...state,
        selectedProductIds: action.payload.productIds,
    };
};

const reduceSetActiveGroupByOption = (
    state : IUnassignedItemsState,
    action : UnassignedItemsActionInterfaces.ISetActiveGroupByOption
) : IUnassignedItemsState => {
    return {
        ...state,
        activeGroupByOption: action.payload.groupByOption,
    };
};

const reduceSetSortedGroupNamesAndSortedProductIdsToDisplayByGroupName = (
    state : IUnassignedItemsState,
    action : UnassignedItemsActionInterfaces.ISetSortedGroupNamesAndSortedProductIdsToDisplayByGroupName
) : IUnassignedItemsState => {
    return {
        ...state,
        sortedProductIdsToDisplayByGroupName: action.payload.sortedProductIdsToDisplayByGroupName,
        sortedGroupNamesToDisplay: action.payload.sortedGroupNamesToDisplay,
        panelIsOpenByGroupNameFromFiltering: action.payload.panelIsOpenByGroupNameFromFiltering,
    };
};

const reduceSetProductsByIdAndStorageAreaIdSetByProductId = (
    state : IUnassignedItemsState,
    action : UnassignedItemsActionInterfaces.ISetProductsByIdAndStorageAreaIdSetByProductId
) : IUnassignedItemsState => {
    return {
        ...state,
        productIdsForDisplay: action.payload.productIdsForDisplay,
        productsById: action.payload.productsById,
        storageAreaIdSetByProductId: action.payload.storageAreaIdSetByProductId,
        storageAreasById: action.payload.storageAreasById,
    };
};

const reduceSetTableSorting = (
    state : IUnassignedItemsState,
    action : UnassignedItemsActionInterfaces.ISetTableSorting
) : IUnassignedItemsState => {
    return {
        ...state,
        tableSorting: action.payload.columnSort,
    };
};

const reduceSetSearchTerm = (
    state : IUnassignedItemsState,
    action : UnassignedItemsActionInterfaces.ISetSearchTerm
) : IUnassignedItemsState => {
    return {
        ...state,
        searchTerm: action.payload.searchTerm,
    };
};

const reduceSetPanelIsOpenFromUserInputForGroupNames = (
    state : IUnassignedItemsState,
    action : UnassignedItemsActionInterfaces.ISetPanelIsOpenFromUserInputForGroupNames
) : IUnassignedItemsState => {
    const newPanelIsOpenByGroupNameFromUserInput = { ...state.panelIsOpenByGroupNameFromUserInput };
    const newPanelIsOpenByGroupNameFromFiltering = { ...state.panelIsOpenByGroupNameFromFiltering };

    action.payload.groupNames.forEach((groupName) => {
        newPanelIsOpenByGroupNameFromUserInput[groupName] = action.payload.panelIsOpen;
        delete newPanelIsOpenByGroupNameFromFiltering[groupName];
    });

    return {
        ...state,
        panelIsOpenByGroupNameFromUserInput: newPanelIsOpenByGroupNameFromUserInput,
        panelIsOpenByGroupNameFromFiltering: newPanelIsOpenByGroupNameFromFiltering,
    };
};

export const UnassignedItemsReducers = (
    state : IUnassignedItemsState = unassignedItemsInitialState,
    action : Action,
) : IUnassignedItemsState => {
    switch (action.type) {
        case ActionTypes.SET_COMPONENT_IS_SHOWN:
            return reduceSetComponentIsShown(state, action as UnassignedItemsActionInterfaces.ISetComponentIsShown);
        case ActionTypes.SET_VISIBLE_DROPDOWN_ROW:
            return reduceSetVisibleDropdownRow(state, action as UnassignedItemsActionInterfaces.ISetVisibleDropdownRow);
        case ActionTypes.SET_MORE_OPTIONS_IS_SHOWN_FOR_PRODUCT_IDS:
            return reduceSetMoreOptionsIsShownForProductIds(state, action as UnassignedItemsActionInterfaces.ISetMoreOptionsIsShownForProductIds);
        case ActionTypes.SET_PRODUCT_ROWS_SELECTED:
            return reduceSetSelectedProductIds(state, action as UnassignedItemsActionInterfaces.ISetSelectedProductIds);
        case ActionTypes.SET_ACTIVE_GROUP_BY_OPTION:
            return reduceSetActiveGroupByOption(state, action as UnassignedItemsActionInterfaces.ISetActiveGroupByOption);
        case ActionTypes.SET_SORTED_GROUP_NAMES_AND_SORTED_PRODUCT_IDS_TO_DISPLAY_BY_GROUP_NAME:
            return reduceSetSortedGroupNamesAndSortedProductIdsToDisplayByGroupName(state, action as UnassignedItemsActionInterfaces.ISetSortedGroupNamesAndSortedProductIdsToDisplayByGroupName);
        case ActionTypes.SET_PRODUCTS_BY_ID_AND_STORAGE_AREA_ID_SET_BY_PRODUCT_ID:
            return reduceSetProductsByIdAndStorageAreaIdSetByProductId(state, action as UnassignedItemsActionInterfaces.ISetProductsByIdAndStorageAreaIdSetByProductId);
        case ActionTypes.SET_TABLE_SORTING:
            return reduceSetTableSorting(state, action as UnassignedItemsActionInterfaces.ISetTableSorting);
        case ActionTypes.SET_SEARCH_TERM:
            return reduceSetSearchTerm(state, action as UnassignedItemsActionInterfaces.ISetSearchTerm);
        case ActionTypes.SET_PANEL_IS_OPEN_FROM_USER_INPUT_FOR_GROUP_NAMES:
            return reduceSetPanelIsOpenFromUserInputForGroupNames(state, action as UnassignedItemsActionInterfaces.ISetPanelIsOpenFromUserInputForGroupNames);
        case ActionTypes.SET_DEFAULT_PANEL_IS_OPEN:
            return reduceSetDefaultPanelIsOpen(state, action as UnassignedItemsActionInterfaces.ISetDefaultPanelIsOpen);
        default:
            return state;
    }
};
